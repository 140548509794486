import { authorizedGetRequest } from '@/api/requests';

const TAGS_LIST = 'tags/list';
const MENTIONS_LIST = 'profiles/mentions/list';

export const getTags = authorizedGetRequest(TAGS_LIST, { ignoreParams: true });
export const getMentions = authorizedGetRequest(MENTIONS_LIST, {
  ignoreParams: true,
});

export default {
  getTags,
  getMentions,
};
