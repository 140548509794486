import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPutRequest,
} from '@/api/requests';

const ORG_BASE = 'organizations';

export const getOrganizations = authorizedGetRequest(ORG_BASE);

export const getOrganizationProfile = (orgname) =>
  authorizedGetRequest(`${ORG_BASE}/${orgname}`);

export const getOrganizationMembers = (orgname) =>
  authorizedGetRequest(`${ORG_BASE}/${orgname}/members`);

export const setOrganizationDetails = (orgname) =>
  authorizedPutRequest(`${ORG_BASE}/${orgname}`);

export const setOrganizationMember = authorizedPutRequest(`${ORG_BASE}/admins`);

export const deleteOrganizationMember = (orgname, member) =>
  authorizedDeleteRequest(`${ORG_BASE}/${orgname}/admins/${member}`);

export const putOrganizationDetails = (orgId) =>
  authorizedPutRequest(`${ORG_BASE}/${orgId}`);

export default {
  getOrganizations,
  getOrganizationProfile,
  getOrganizationMembers,
  setOrganizationDetails,
  setOrganizationMember,
  deleteOrganizationMember,
  putOrganizationDetails,
};
