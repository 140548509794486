import '@babel/polyfill';
import 'geometry-polyfill';
import 'vue-svgicon/dist/polyfill';
import '@/utils/extends';

import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
/*//dirty hack to clear user info
store.dispatch('logout');*/
import scssVariables from '@/assets/scss/phcp/export.scss';
import * as lodash from 'lodash';
import router from './router';
import Notifications from 'vue-notification';
import SvgIcon from 'vue-svgicon';
import VeeValidate from 'vee-validate';
import '@/utils/validationRules';
import interceptors from '@/api/interceptors';

import VueTheMask from 'vue-the-mask';
import { API } from '@/api/config';
import layouts from '@/layouts/index.js';
import filters from '@/filters';
import directives from '@/directives/common';
import vuexI18n from 'vuex-i18n';
import vuexI18nOptionsFactory from '@/utils/vuexi18nUtils';

import messagesEn from 'vee-validate/dist/locale/en.js';
import messagesAr from 'vee-validate/dist/locale/ar.js';
import messagesJa from 'vee-validate/dist/locale/ja.js';
// TODO: delete fr and ko languages https://isddesign.atlassian.net/browse/TH-7090
import messagesFr from 'vee-validate/dist/locale/fr.js';
import messagesKo from 'vee-validate/dist/locale/ko.js';
import { USER_LOCALE, WE_HAVE_USER_LOCALE } from '@/api/user-locale';

import(/* webpackChunkName: "icons-sprite" */ './icons');

API.interceptors.response.use(
  interceptors.onResponseSuccess,
  interceptors.onTokenFailure.bind({ $store: store, $router: router })
);

layouts(['admin-layout', 'admin-auth-layout']).forEach((layout) => {
  Vue.component(layout.name, layout.component);
});

Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

Vue.prototype.$scss = Object.freeze(scssVariables);
Vue.prototype.$lodash = Object.freeze(lodash);

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  locale: WE_HAVE_USER_LOCALE ? USER_LOCALE.slice(0, 2) : 'en',
  dictionary: {
    en: { messages: messagesEn },
    ar: { messages: messagesAr },
    fr: { messages: messagesFr },
    ko: { messages: messagesKo },
    ja: { messages: messagesJa },
  },
  inject: false,
});

Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(SvgIcon, {
  tagName: 'svgicon',
  classPrefix: 'AppIcon-',
});

for (let name in filters) {
  Vue.filter(name, filters[name]);
}

for (let name in directives) {
  Vue.directive(name, directives[name]);
}

Vue.use(vuexI18n.plugin, store, vuexI18nOptionsFactory(store));

window.addEventListener('storage', ({ key, newValue }) => {
  const tokenKeys = ['refreshToken', 'accessToken', 'tempToken'];
  if (!tokenKeys.includes(key) || store.getters[key] === newValue) {
    //do nothing
  } else if (!newValue) {
    router.push({ name: 'signout' });
  } else {
    const token = JSON.parse(newValue);
    store.commit('setTokenInStore', { tokenKey: key, token });
  }
});

// eslint-disable-next-line
window.vm = new Vue({
  router,
  store,
  created() {
    API.interceptors.request.use(interceptors.onRequestSuccess.bind(this));
    API.interceptors.response.use(
      interceptors.onResponseSuccess.bind(this),
      interceptors.onResponseError.bind(this)
    );
    this.$store.commit('translationsFetched');
    this.$i18n.set('en-US');
  },
  render: (h) => h(App),
});
