export default (colors) => ({
  chart: {
    background: {
      opacity: 0,
    },
  },
  title: {
    color: colors.textColor,
    fontSize: 16,
    fontWeight: 'bold',
  },
  plot: {
    background: 'transparent',
  },
  xAxis: {
    label: {
      color: colors.textColor,
    },
    tickColor: colors.textColor,
  },
  yAxis: {
    label: {
      color: colors.textColor,
    },
    tickColor: colors.textColor,
  },
  series: {
    colors: Object.keys(colors).reduce(
      (acc, colorName) =>
        colorName.toLowerCase().indexOf('chartpalette') > -1
          ? [...acc, colors[colorName]]
          : acc,
      []
    ),
  },
  legend: {
    label: {
      color: colors.textColor,
    },
  },
});
