// import store from '@/store';
// import { STATIC_PAGES } from './static-pages.js';
import { areRoutesEqual } from '@/utils';
import { cancelAllRequests } from '@/api/requestCancel';

// export const fromStatic = (to, from, next) => {
//   if (STATIC_PAGES.includes(from.name)) {
//     next(from);
//   } else {
//     next();
//   }
// };
export const ifNotAuthorized = (store) => (to, from, next) => {
  !store.getters.isAuthorized ? next() : next({ path: '/' });
};
export const ifAuthenticated = (store) => (to, from, next) => {
  store.getters.isAuthenticated ? next() : next({ name: 'signin' });
};
export const ifPartiallyAuthorized = (store) => (to, from, next) => {
  store.getters.isPartiallyAuthorized &&
  from.name === 'verifyCode' &&
  from.params.from === 'reset'
    ? next()
    : next({ name: 'signin' });
};
export const ifAuthorized = (store) => (to, from, next) => {
  if (store.getters.isAuthorized) {
    next();
  } else {
    next({ name: 'signin', params: { redirectTo: to.fullPath } });
  }
};

export const requestCancellingOnNavigation = (to, from, next) => {
  if (!areRoutesEqual(to, from)) {
    cancelAllRequests();
  }
  next();
};

// export default {
//   ifNotAuthorized,
//   ifAuthenticated,
//   ifPartiallyAuthorized,
//   ifAuthorized,
//   requestCancellingOnNavigation
// };
