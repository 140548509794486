import // CLAN,
// COUNT_FOLLOWERS,
// COUNT_FOLLOWING,
// COUNT_FRIENDS,
// COUNT_MEMBERS,
// ORGANIZATION,
// PROFILE,
'@/constants/accounts';

/**
 * This file contains paths to modal components over the app
 * in case of dynamic components these paths have compound names
 * from other constants like
 * `${PROFILE}_${COUNT_FOLLOWERS}`
 * All modal components will be imported dynamically with webpack
 * chunks splitting for minimizing initial bundle size
 */

export const modalComponents = {
  /**
   * LockScreen modal component
   * @return {Component} Imported component
   */
  LockScreen: () =>
    import(
      /* webpackChunkName: "ModalComponent-[request]" */
      '@/components/common/Loader.vue'
    ),
  /**
   * Confirmation modal component
   * @return {Component} Imported component
   */
  HeraConfirm: () =>
    import(
      /* webpackChunkName: "ModalComponent-[request]" */
      '@/components/modal/children/HeraConfirm.vue'
    ),
  /**
   * Confirmation leave modal component
   * @return {Component} Imported component
   */ HeraConfirmLeave: () =>
    import(
      /* webpackChunkName: "ModalComponent-[request]" */
      '@/components/modal/children/HeraConfirmLeave.vue'
    ),
  /**
   * Invite to tournament modal component
   * @return {Component} Imported component
   */
  // InviteToTournament: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/tournaments/management/invites/TournamentsListForInvites.vue'),
  // account statusBar
  /**
   * Profile followers modal component
   * @return {Component} Imported component
   */
  // [`${PROFILE}_${COUNT_FOLLOWERS}`]: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/account/lists/ProfileFollowers'),
  /**
   * Profile followings modal component
   * @return {Component} Imported component
   */
  // [`${PROFILE}_${COUNT_FOLLOWING}`]: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/account/lists/ProfileFollowing'),
  /**
   * Profile friends modal component
   * @return {Component} Imported component
   */
  /*  [`${PROFILE}_${COUNT_FRIENDS}`]: () =>
    import(/!* webpackChunkName: "ModalComponent-[request]" *!/
      '@/views/account/lists/ProfileFriends.vue'),*/
  /**
   * Organization followers modal component
   * @return {Component} Imported component
   */
  // [`${ORGANIZATION}_${COUNT_FOLLOWERS}`]: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/account/lists/OrganizationFollowers'),
  /**
   * Organization members modal component
   * @return {Component} Imported component
   */
  // [`${ORGANIZATION}_${COUNT_MEMBERS}`]: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/account/lists/OrganizationMembers'),
  /**
   * Clan followers modal component
   * @return {Component} Imported component
   */
  // [`${CLAN}_${COUNT_FOLLOWERS}`]: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/account/lists/ClanFollowers'),
  /**
   * Clan members modal component
   * @return {Component} Imported component
   */
  // [`${CLAN}_${COUNT_MEMBERS}`]: () =>
  //   import(/* webpackChunkName: "ModalComponent-[request]" */
  //   '@/_app_main/views/account/lists/ClanMembers'),
};
