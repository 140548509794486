<template>
  <img
    :src="avatar"
    class="playhera-components-avatar"
    loading="lazy"
    :style="{
      width: `${size}px`,
      minWidth: `${size}px`,
      height: `${size}px`,
      '--background-color': fillColor,
    }"
    @error="updateImage"
  />
</template>

<script>
import { prop } from 'playhera-utils';
import images from './defaultImages';

export default {
  name: 'Avatar',
  props: {
    size: prop([Number, String], 96),
    imgUrl: prop(null, ''),
    fillColor: prop(String),
    type: prop(String),
    autoSizes: prop(Boolean, true),
    isDeleted: prop(Boolean),
  },
  data() {
    const sourceSize = !this.autoSizes
      ? -1
      : this.size <= 100
      ? 100
      : this.size <= 300
      ? 300
      : this.size <= 500
      ? 500
      : -1;

    return {
      sourceSize,
      currentAvatar: '',
    };
  },
  computed: {
    url() {
      return String(this.imgUrl || '');
    },
    avatar() {
      return (
        (this.isDeleted ? this.getDeletedAvatar() : this.currentAvatar) ||
        (!this.url
          ? this.getDefaultAvatar()
          : this.checkAutoSize()
          ? this.url.replace('.original.', `.${this.sourceSize}.`)
          : this.url)
      );
    },
  },
  watch: {
    imgUrl() {
      this.currentAvatar = ''; // drop avatars if changed;
    },
  },
  methods: {
    checkAutoSize() {
      return (
        this.autoSizes &&
        this.url.includes('.original.') &&
        this.sourceSize !== -1
      );
    },
    updateImage() {
      this.currentAvatar = this.checkAutoSize()
        ? this.getNextSizeAvatar()
        : this.getDefaultAvatar();
    },
    getNextSizeAvatar() {
      this.sourceSize = -1;
      return this.url;
    },
    getDefaultAvatar() {
      switch (this.type) {
        case 'user':
        case 'profile':
          return images.defaultUser;
        case 'clan':
          return images.defaultClan;
        case 'federation':
        case 'organization':
          return images.defaultOrganization;
        case 'platform':
        case 'genre':
        case 'game':
          return images.defaultGame;
        case 'espl':
        case 'league':
          return images.defaultESPL;
        case 'channel':
          return images.defaultChannels;
        case 'group':
          return images.defaultGroup;
        default:
          return images.defaultImage;
      }
    },
  },
};
</script>

<style lang="scss">
.playhera-components-avatar {
  display: inline-block;
  position: relative;
  object-fit: cover;
  background: var(--background-color);
  mask: url(~./assets/mask.svg) center top no-repeat;
  mask-size: contain;
  overflow: hidden;
}
</style>
