<template>
  <div :class="{ animated }">
    <icon
      v-show="animated || isFull"
      name="full_logo"
      width="150"
      fill="white"
      class="full-logo main-logo"
      :class="{ visible: isFull }"
    />
    <icon
      v-show="animated || !isFull"
      name="short_logo"
      width="18"
      fill="white"
      class="short-logo main-logo"
      :class="{ visible: !isFull }"
    />
  </div>
</template>
<script>
import icon from './Icon';

export default {
  name: 'HeraLogo',
  components: {
    icon,
  },
  props: {
    type: {
      type: String,
      default: 'full',
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFull() {
      return this.type === 'full';
    },
  },
};
</script>
<style lang="scss" scoped>
.animated {
  position: relative;
  height: 100%;

  .main-logo {
    position: absolute;
    opacity: 0;
    transition: all 0.3s linear;
    top: 50%;

    &.full-logo {
      .ltr & {
        left: 0;
      }

      .rtl & {
        right: 0;
      }

      transform: translateY(-50%);
    }

    &.short-logo {
      .ltr & {
        left: calc(29px - 1rem); /* find out why so tricky */
        transform: translate(-50%, -50%);
      }

      .rtl & {
        right: calc(29px - 1rem); /* find out why so tricky */
        transform: translate(50%, -50%);
      }
    }
  }
  .visible {
    opacity: 1;
  }
}
</style>
