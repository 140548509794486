import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const CLANS_BASE = 'clans';

const postClan = authorizedPostRequest(`${CLANS_BASE}/`);

const getClan = (clanID) => authorizedGetRequest(`${CLANS_BASE}/${clanID}`);

const getClans = (accessToken, params) =>
  authorizedGetRequest(`${CLANS_BASE}/`)(accessToken, { params });

const followClan = (accessToken, id, params) =>
  authorizedPostRequest(`${CLANS_BASE}/${id}/follows`)(accessToken, params);

const inviteClanMember = (accessToken, clan, candidates) =>
  authorizedPostRequest(`${CLANS_BASE}/${clan}/invites`)(accessToken, {
    candidates,
  });

const cancelClanMember = (accessToken, clanID, login) =>
  authorizedDeleteRequest(`${CLANS_BASE}/${clanID}/members/${login}`)(
    accessToken
  );

const updateClan = (accessToken, id, data) =>
  authorizedPutRequest(`${CLANS_BASE}/${id}`)(accessToken, data);

const joinClan = (accessToken, id) =>
  authorizedPostRequest(`${CLANS_BASE}/${id}/requests`)(accessToken);

const getUserClan = (userId) =>
  authorizedGetRequest(`${CLANS_BASE}/${userId}/user`);

const getPopularClans = authorizedGetRequest(`${CLANS_BASE}/my/popular`);

export default {
  postClan,
  getClan,
  getClans,
  followClan,
  inviteClanMember,
  cancelClanMember,
  updateClan,
  joinClan,
  getUserClan,
  getPopularClans,
};
