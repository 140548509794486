/**
 * TODO:
 * 1. Think about use AccountModel from hera-web
 * 2. Use displayName filter from hera-web
 */

import api from '@/api';
import { generateMutations, normalize } from '@/utils/store';

const INITIAL_STATE = () => ({
  isLoading: false,
  data: {},
});

const getters = {
  name: ({ data }) => data.displayName || data.name || data.login || data.id,
};

const mutations = {
  ...generateMutations('isLoading', 'data'),
};

const actions = {
  fetch({ rootGetters: { accessToken }, commit }) {
    commit('setIsLoading', true);
    return api.profiles
      .getMyProfile(accessToken)
      .then(({ data }) => commit('setData', normalize(data)))
      .finally(() => commit('setIsLoading', false));
  },
};

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  getters,
  mutations,
  actions,
};
