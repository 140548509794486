<template>
  <Layout
    v-bind="{
      title: 'Welcome to PLAYHERA!',
      subtitle: 'Please, login to your account',
      btnLabel: $t('_web_signin_submit_button', 'Login'),
      isLoading,
    }"
    @submit="formSubmit"
  >
    <TextInput
      v-model.trim="login"
      v-validate="'required|email_or_phone'"
      name="login"
      label="Email or Phone"
      :error="errors.first('login')"
      class="mb-2 w-100"
    />

    <TextInput
      v-model.trim="password"
      v-validate="'required'"
      name="password"
      type="password"
      label="Password"
      :error="errors.first('password')"
      class="mb-5 w-100"
      @input="$validator.validate('login')"
    >
      <router-link slot="additionalLabel" :to="{ name: 'resetPassword' }">
        Forgot Password
      </router-link>
    </TextInput>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isLoadingMixin from '@/mixins/isLoading';
import { TextInputWithLabelAndError as TextInput } from 'playhera-components';
import Layout from '../../layouts/AuthLayout';

export default {
  name: 'SignIn',
  components: {
    TextInput,
    Layout,
  },
  mixins: [isLoadingMixin],
  inject: ['$validator'],
  data() {
    return {
      login: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters(['permissions']),
  },
  methods: {
    ...mapActions(['authRequest', 'logout', 'errorNotify']),
    formSubmit() {
      if (!this.errors.count()) {
        this.setLoading();
        this.$validator
          .validateAll()
          .then(
            (result) =>
              result
                ? Promise.resolve({
                    login: this.login,
                    password: this.password,
                    scopes: 'application,administrative',
                  })
                : Promise.reject() // eslint-disable-line
          )
          .then(this.authRequest)
          .then(() => {
            if (!this.permissions.fullPermissions) {
              this.logout();
              throw 'You do not have permission to access the PHCP.';
            }
            const path = this.$route.params.redirectTo || '/';
            this.$router.push({ path });
          })
          .catch((error) => !error.isValidationError && this.errorNotify(error))
          .finally(this.unsetLoading);
      }
    },
  },
};
</script>

<style lang="scss">
.signin {
  form {
    max-width: 18.75rem;
  }
}
</style>
