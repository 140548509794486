import axios from 'axios';
// import { isTokenValid } from '@/utils';
import { errorMessagePropsFactory } from '@/utils/textError';
import { cloneDeep, snakeCase } from 'lodash';
import { isRequestCanceled } from './requestCancel';

const updateTokenInConfig = function (config) {
  const newConfig = cloneDeep(config);
  if (newConfig.headers.common) {
    newConfig.headers.common['Authorization'] = this.$store.getters.accessToken;
  } else {
    newConfig.headers['Authorization'] = this.$store.getters.accessToken;
  }
  return newConfig;
};

export function onTokenFailure(error) {
  if (isRequestCanceled(error)) {
    throw error;
  }
  if (error.response) {
    const { status, config } = error.response;
    let refreshChain;
    const isRefreshRequest =
      config.headers['Authorization'] === this.$store.getters.refreshToken;
    let setToken = updateTokenInConfig.bind(this);
    // do we refreshing tokens right now?
    if (status === 401) {
      // we can't refresh token if we don't have one
      if (
        this.$store.getters.isTempTokenOnly ||
        this.$store.getters.areAllTokensInvalid
      ) {
        this.$store.commit('clearToken', 'tempToken');
        throw error;
      } else if (isRefreshRequest) {
        // if we have an error while refreshing token -> bypass this error further
        throw error;
      } else if (this.$store.getters.isRefreshProcessing) {
        // else if  it's another request so get refreshing promise
        refreshChain = this.$store.getters.refreshStopover;
      } else {
        // otherwise start new refresh process
        let refreshPromise = this.$store.dispatch('refreshAccessToken');
        this.$store.commit('changeRefreshStopover', refreshPromise);
        refreshChain = refreshPromise;
      }
      // after refresh we need to retry failed request
      return refreshChain
        .catch((refreshError) => {
          // logout user on any refresh error
          this.$router.push({ name: 'signout' });
          throw refreshError;
        })
        .then(() => setToken(config))
        .then(axios.request)
        .catch((anotherError) => {
          throw anotherError;
        });
    } else {
      throw error;
    }
  } else {
    throw error;
  }
}

export function onRequestSuccess(config) {
  const self = this;

  let setToken = updateTokenInConfig.bind(self);

  return new Promise((resolve, reject) => {
    const token = config.headers.common['Authorization'];

    // in the case when user delete the maintenance popup from DOM and try to interact with the app
    if (self.$store.getters.maintenance) {
      return reject({
        response: {
          status: 405,
          data: { message: 'You shall not pass!🧙' },
          config,
        },
      });
    }

    const onRefreshError = (e) => {
      reject({ message: e, url: config.url });
    };

    if (
      token &&
      token !== self.$store.getters.refreshToken &&
      self.$store.getters.isRefreshProcessing
    ) {
      self.$store.getters.refreshStopover
        .then(() => {
          return resolve(setToken(config));
        })
        .catch(onRefreshError);
    } else {
      resolve(config);
    }
  });
}

export function onResponseSuccess(response) {
  return response;
}

export function onResponseError(error) {
  if (isRequestCanceled(error)) {
    throw { silent: true, message: 'Request cancelled' };
  }
  let text = error.message;
  let silent = false;
  let inModal = false;
  const yesText = this.$t('_web_form_error_confirm-yes', 'OK');
  if (error.response) {
    const { status, data, config } = error.response;
    if (error.config && error.config.data === 'unsetInterceptorReplacer') {
      throw { silent: true, message: data };
    }
    const requestMethod = error.config.method.toUpperCase();
    const hasEndpoint = error.config.url.match(/\/api\/.*$/i);
    const isRefreshRequest =
      config.headers['Authorization'] === this.$store.getters.refreshToken;
    const endpoint = hasEndpoint ? hasEndpoint[0] : '';
    text = data.message || '';
    let textGenerated = '';
    switch (status) {
      case 400:
        if (data.message === 'Validation failed' && data.fields) {
          Object.entries(data.fields).forEach(([key, value]) => {
            const fieldErrors = value.reduce((acc, fieldError) => {
              if (fieldError.context && fieldError.context.message) {
                acc.push(`${key}: ${fieldError.context.message}`);
              } else {
                const {
                  translateKey,
                  translateDefault,
                  props = {},
                } = errorMessagePropsFactory(fieldError, key);
                textGenerated = this.$t(translateKey, translateDefault, {
                  ...props,
                  field: key,
                }); // only 1 message (and last)
                const fieldName = key.toLowerCase();
                this.$validator.errors.add({
                  field: fieldName,
                  msg: textGenerated,
                });
              }
              return acc;
            }, []);

            if (fieldErrors.length) {
              text += `<br>${fieldErrors.join('<br>')}`;
            } else if (textGenerated) {
              text = textGenerated;
            }
          });

          text = { message: text, isValidationError: true };
        }
        break;
      case 401:
        // do we refreshing tokens right now?
        if (
          isRefreshRequest ||
          this.$store.getters.isTempTokenOnly ||
          this.$store.getters.areAllTokensInvalid
        ) {
          text = this.$t('_web_session_expired_error', 'Session expired');
          inModal = true;
        }
        break;
      case 403:
        // fix for the specific case when template token was expired before user verify phone on 3 step of registration
        if (
          config.url.includes('auth/sendcode') &&
          config.data &&
          JSON.parse(config.data).type === 'phone'
        ) {
          text = this.$t(
            '_web_auth_sendcode_error',
            'Session for adding phone is expired. You will be able to add a phone number in you profile settings.'
          );
          inModal = true;
        } else {
          text = this.$t('_web_auth_error', 'Access denied');
          inModal = true;
        }
        break;
      case 404:
        text = `<strong>${requestMethod}&nbsp;${endpoint}</strong><br>${this.$t(
          '_web_404_error',
          'Wrong URI or endpoint not implemented yet'
        )}`;
        break;
      case 500:
        text = this.$t('_web_network_error', 'Internal server error');
        break;
      case 503:
        text = this.$t('_web_server_error', 'Service Unavailable');
        break;
      default:
        if (data.fields) {
          Object.keys(data.fields).forEach((key) => {
            const fieldName = key.toLowerCase();
            data.fields[key].forEach((item) => {
              if (typeof item.context === 'string') {
                this.$validator.errors.add({
                  field: fieldName,
                  msg: this.$t(
                    `_web_field_errors_${snakeCase(item.error)}`,
                    item.context
                  ),
                });
              }
            });
          });
        }
        text = this.$t(
          `_web_form_errors_${snakeCase(data.message)}`,
          data.message
        );
    }
  } else if (text === 'Network Error') {
    text = this.$t(
      '_web_internet_connection_error',
      'Network error. Please check your internet connection.'
    );
    inModal = true;
  }
  throw { silent, inModal, yesText, message: text };
}

export default {
  onRequestSuccess,
  onResponseSuccess,
  onResponseError,
  onTokenFailure,
};
