import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests.js';

const BATTLES_BASE = 'battles';

export default {
  getList: (pageToken = null, eventId = '') =>
    authorizedGetRequest(
      `${BATTLES_BASE}?event_id=${encodeURIComponent(eventId)}${
        pageToken ? `&page=${encodeURIComponent(pageToken)}` : ``
      }`
    ),
  getById: (battleID) => authorizedGetRequest(`${BATTLES_BASE}/${battleID}`),
  getConflicted: (pageToken = null) =>
    authorizedGetRequest(
      `${BATTLES_BASE}/conflicted${
        pageToken ? `?page=${encodeURIComponent(pageToken)}` : ``
      }`
    ),
  getResults: (battleID) =>
    authorizedGetRequest(`${BATTLES_BASE}/${battleID}/results`),
  getResultsAll: (competitionID) =>
    authorizedGetRequest(`${BATTLES_BASE}/grid/${competitionID}`, {
      ignoreParams: true,
    }),
  resolve: (tournamentId, stageId, blockId, matchId) =>
    authorizedPostRequest(
      `tournaments/${tournamentId}/stages/${stageId}/blocks/${blockId}/matches/${matchId}/resolution`
    ),
  cancel: (battleID) =>
    authorizedPutRequest(`${BATTLES_BASE}/${battleID}/cancel`),
  // will be deprecated soon
  provideOfflineResult: (battleID) =>
    authorizedPostRequest(`${BATTLES_BASE}/${battleID}/results/offline`),
};
