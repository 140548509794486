var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-activity"},[_c('Title',{staticClass:"mb-2",attrs:{"text":"Overview totals"}}),_c('TextBlock',{attrs:{"variant":"secondary","size":0.875}},[_vm._v("Time:")]),_c('Dropdown',{staticClass:"mb-3",attrs:{"variant":"primary-button","list":_vm.statisticPeriodsKeys},on:{"input":function () { return _vm.fetchMetrics(); }},scopedSlots:_vm._u([{key:"chosen",fn:function(ref){
var chosenItem = ref.chosenItem;
return [_c('TextBlock',{attrs:{"variant":"light","size":0.875}},[_vm._v(_vm._s(_vm._f("startCase")(chosenItem)))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('TextBlock',{attrs:{"size":0.875}},[_vm._v(_vm._s(_vm._f("startCase")(item)))])]}}]),model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('div',{staticClass:"row mb-3"},_vm._l((_vm.visibleCounters),function(counter){return _c('div',{key:("counter-" + (counter.label)),staticClass:"col-xl-4 col-md-6 col-sm-12 pb-4"},[_c('StatisticPanel',{staticClass:"cursor-hand h-100",class:[
          counter.label === _vm.activeCounter.label
            ? 'border-primary'
            : 'border-transparent' ],attrs:{"label":counter.label,"total":counter.total,"options":counter.options,"variant":counter.label === _vm.activeCounter.label ? 'transparent' : 'hover',"is-loading":counter.isLoading,"has-error":counter.hasError},nativeOn:{"click":function($event){return _vm.updateChartData(counter)}}})],1)}),0),_c('Subheader',{staticClass:"mb-3",attrs:{"text":_vm.activeCounter.label}}),_c('div',{ref:"chartWrapper"},[_c('line-chart',{key:_vm.lineChartRenderKey,attrs:{"data":_vm.lineChartData,"options":_vm.lineChartOptions,"theme":_vm.theme}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }