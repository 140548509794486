import auth from './endpoints/auth';
import globalSettings from './endpoints/globalSettings';
import settings from './endpoints/settings';
import files from './endpoints/files';
import translations from './endpoints/translations';
import profiles from './endpoints/profiles';
import players from './endpoints/players';
import clans from './endpoints/clans';
import conversations from './endpoints/conversations';
import posts from './endpoints/posts';
import maps from './endpoints/maps';
import tags from './endpoints/tags';
import requests from './endpoints/requests';
import notifications from './endpoints/notifications';
import organizations from './endpoints/organizations';
import proleagues from './endpoints/proleagues';
import competitions from './endpoints/competitions';
import battles from './endpoints/battles';
import statistic from './endpoints/statistic';
import tournaments from './endpoints/tournaments';
import newsfeeds from './endpoints/newsfeeds';
import comments from './endpoints/comments';
import complaints from './endpoints/complaints';
import reports from './endpoints/phcp';

export default {
  auth,
  globalSettings,
  settings,
  translations,
  files,
  conversations,
  maps,
  posts,
  clans,
  profiles,
  players,
  tags,
  requests,
  notifications,
  organizations,
  proleagues,
  competitions,
  battles,
  statistic,
  tournaments,
  newsfeeds,
  comments,
  complaints,
  reports,
};
