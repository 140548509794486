/*
export const INITIAL_REQUESTS_TOKENS_STATE = () => ({
  requestsTokens: new Map(),
});

const getters = {
  requestsTokens: state => state.requestsTokens,
  tokensByUrl: state => url => {
    return Array.from(state.requestsTokens.values()).filter(
      ({ requestUrl }) => requestUrl === url
    );
  },
};

const mutations = {
  addToken(state, { requestId, requestUrl, token }) {
    state.requestsTokens.set(requestId, { requestUrl, token });
  },
  deleteToken(state, id) {
    state.requestsTokens.delete(id);
  },
};

export default {
  state: INITIAL_REQUESTS_TOKENS_STATE(),
  getters,
  mutations,
};
*/

window.requestsTokens = new Map();

export const requestsTokens = window.requestsTokens;
export const tokensByUrl = (url) => {
  return Array.from(requestsTokens.values()).filter(
    ({ requestUrl }) => requestUrl === url
  );
};

export const addToken = ({ requestId, requestUrl, token }) => {
  requestsTokens.set(requestId, { requestUrl, token });
};

export const deleteToken = (id) => {
  requestsTokens.delete(id);
};
