import tokens from './authTokens.js';
import profile, { INITIAL_PROFILE_STATE } from './profile.js';
import settings, { INITIAL_USER_SETTINGS_STATE } from './settings.js';
import api from '@/api';
import { isTokenValid } from '@/utils';

const modules = {
  tokens,
  profile,
  settings,
};
const getters = {};
const mutations = {
  logout(state) {
    state.profile = INITIAL_PROFILE_STATE();
    state.settings = INITIAL_USER_SETTINGS_STATE();

    state.tokens.tempToken = {};
    localStorage.removeItem('PHCPtempToken');
    state.tokens.accessToken = {};
    localStorage.removeItem('PHCPaccessToken');
    state.tokens.refreshToken = {};
    localStorage.removeItem('PHCPrefreshToken');
  },
};

const actions = {
  logout: ({ getters, commit, dispatch }) => {
    return new Promise((resolve) => {
      dispatch('setDefaultState');
      if (isTokenValid(getters.tokenObject('accessToken'))) {
        api.auth.signOut(getters.accessToken);
      }
      //commit('clearFlags')
      commit('clearPermissions');
      dispatch('clearAllTokens');
      resolve();
    });
  },
  setDefaultState: ({ commit }) => {
    commit('clearToUserDefaultState');
    // commit('clearToClansDefaultState')
    // commit('clearToTranslationsDefaultState')
    // commit('clearToFlagsDefaultState')
    // commit('clearToSettingsDefaultState')
    // commit('clearToRegistrationsDefaultState')
    commit('clearToNotificationsDefaultState');
    // commit('clearToConversationsDefaultState');
    // commit('clearToCounterDefaultState');
    // commit('clearToConversationsDefaultState')
    // commit('clearToTournamentsDefaultState');
    // commit('clearToBattlesDefaultState')
    commit('setRefreshProcessing', false);
  },
};

export default {
  modules,
  getters,
  mutations,
  actions,
};
