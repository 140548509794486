import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import scssVariables from '@/assets/scss/main/export.scss';

import { requestCancellingOnNavigation } from './guards.js';

import authRoutes from '@/features/auth/routes';
import dashboardRoutes from '@/features/dashboard/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...authRoutes,
    ...dashboardRoutes,
    {
      path: '/*',
      redirect: { name: 'dashboard' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const $nav = document.querySelector('#nav');
      const navHeight = $nav
        ? $nav.clientHeight || scssVariables.navbarHeight
        : 0;
      return (
        !to.params.isScrollHandlingDisabled && {
          selector: to.hash,
          offset: { x: 0, y: navHeight },
        }
      );
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(requestCancellingOnNavigation);

router.beforeResolve((to, from, next) => {
  const hasNotAccess = to.matched.some(
    ({ meta }) =>
      !(meta && meta.permission
        ? store.getters.hasAccessByPermission(meta.permission)
        : true)
  );
  if (hasNotAccess) {
    next('/');
  } else {
    next();
  }
});

export default router;
