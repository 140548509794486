import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const getTournamentPlayers = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players`, {
    ignoreParams: true,
  });

export const setTournamentPlayerParams = (tournamentID = null, login = '') =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players/${login}`);

export const setTournamentPlayersExtendedData = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players`);

export const getTournamentAttributes = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players_attributes`);

export const setFillParticipants = (tournamentID = null) =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/players/fill`);

export const createTournamentPlayersCSV = (tournamentID) =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/players/csv`);

export default {
  getTournamentPlayers,
  setTournamentPlayerParams,
  setTournamentPlayersExtendedData,
  getTournamentAttributes,
  setFillParticipants,
  createTournamentPlayersCSV,
};
