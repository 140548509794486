import api from '@/api';
export const INITIAL_METRICS_STATE = () => ({});

const getters = {};

const mutations = {};

const actions = {
  getCoreStatisticsMetrics({ getters: { accessToken } }, params) {
    return api.reports
      .getMetrics(accessToken, { params })
      .then(({ data }) => data);
  },
};

export default {
  state: INITIAL_METRICS_STATE(),
  getters,
  mutations,
  actions,
};
