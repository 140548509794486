import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
  authorizedDeleteRequest,
} from '@/api/requests';

const NOTIFICATIONS_BASE = 'notifications';

export const getNotifications = authorizedGetRequest(NOTIFICATIONS_BASE, {
  preventCancelRequest: true,
});

export const postNotificationAction = (id, action) =>
  authorizedPostRequest(`${NOTIFICATIONS_BASE}/${id}/${action}`);

export const updateNotificationStatus = (id) =>
  authorizedPutRequest(`${NOTIFICATIONS_BASE}/${id}`);

export const deleteNotification = (id) =>
  authorizedDeleteRequest(`${NOTIFICATIONS_BASE}/${id}`);

export const getHubConnection = (hub) =>
  authorizedGetRequest(`${NOTIFICATIONS_BASE}/negotiate/${hub}`);

export default {
  getNotifications,
  postNotificationAction,
  updateNotificationStatus,
  deleteNotification,
  getHubConnection,
};
