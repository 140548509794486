import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
} from '@/api/requests';

const POSTS = 'posts';
const POLL_POSTS = `${POSTS}/my/polls`;
const VOTE_POLL_POST = `${POSTS}/my/vote`;

const getWall = (login = '', pageToken = null) =>
  authorizedGetRequest(
    `${POSTS}/${login}/wall/` + (pageToken ? `?page=${pageToken}` : '')
  );

const getClanWall = (clanID, pageToken = null) =>
  authorizedGetRequest(
    `${POSTS}/clans/${clanID}/wall/` + (pageToken ? `?page=${pageToken}` : '')
  );

const getPost = (accessToken, postId) =>
  authorizedGetRequest(`${POSTS}/${postId}`)(accessToken);

const createPost = authorizedPostRequest(POSTS);

const deletePost = (postID) => authorizedDeleteRequest(`${POSTS}/${postID}`);

const likePost = (postID) =>
  authorizedPostRequest(`${POSTS}/${postID}/reactions`);

const postRepost = (accessToken, params) =>
  authorizedPostRequest(POSTS + '/my/reposts')(accessToken, params);

const createPollPost = authorizedPostRequest(POLL_POSTS);

const votePollPost = ({ pollId, optionId }) =>
  authorizedPostRequest(`${VOTE_POLL_POST}/${pollId}/${optionId}`);

export default {
  getWall,
  getClanWall,
  getPost,
  createPost,
  deletePost,
  likePost,
  postRepost,
  createPollPost,
  votePollPost,
};
