import { authorizedGetRequest, authorizedPostRequest } from '@/api/requests';

const MAP = 'map/map';
const MY_MAP = 'map/my';

const getMapMarkers = authorizedGetRequest(MAP);
const setMyPosition = authorizedPostRequest(MY_MAP);

export default {
  getMapMarkers,
  setMyPosition,
};
