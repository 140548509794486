// import store from '@/store';
import {
  addToken,
  tokensByUrl,
  deleteToken,
  requestsTokens,
} from '@/utils/requestCancel';
import axios from 'axios';
import { cloneDeep } from 'lodash';

// const excludeEndPointsList = ['profiles', 'settings', 'conversations', 'notification', 'platforms'];

const createCancelToken = () => {
  return axios.CancelToken.source();
};

const updateRequestCancelToken = (requestUrl, requestId) => {
  const token = createCancelToken();
  addToken({ requestUrl, token, requestId });
  return token;
};

const cancelRequest = (cancelToken) => {
  cancelToken.cancel('Operation canceled due to new request');
  return cancelToken.token.promise;
};

const resetRequestCancelToken = (url) => {
  const cancelTokens = tokensByUrl(url);
  cancelTokens.forEach((item) => cancelRequest(item.token));
};

const removeTokenFromStore = (requestId) => {
  deleteToken(requestId);
};

const optionsWithCancelToken = (options, url, requestId) => {
  resetRequestCancelToken(url);
  const token = updateRequestCancelToken(url, requestId).token;
  const props = cloneDeep(options || {});
  props.cancelToken = token;
  return props;
};

const isRequestCanceled = (response) => axios.isCancel(response);

const cancelAllRequests = () => {
  return Promise.all(
    Object.entries(requestsTokens).map(([item]) => cancelRequest(item.token))
  );
};

const isRequestPending = (url) => tokensByUrl(url).length > 0;

export {
  optionsWithCancelToken,
  removeTokenFromStore,
  isRequestCanceled,
  cancelAllRequests,
  isRequestPending,
};
