import api from '@/api';

const defaultVuexI18nOptions = {
  moduleName: 'i18n',
};

/*
to turn on auto translation mode
add in localStorage `translateMode`. Ex:
localStorage.setItem('translateMode', 1);
*/
let translatingInProgress = false;
const translatePromises = [];
const translateKeys = new Set();

const putTranslates = (store) => {
  const translateArguments = translatePromises.splice(0, 1)[0];
  api.translations
    .putTranslation(translateArguments.splice(0, 1)[0])(
      store.getters.accessToken,
      ...translateArguments
    )
    .finally(() => {
      if (translatePromises.length) {
        putTranslates();
      } else {
        translatingInProgress = false;
      }
    });
};

const onTranslationNotFound = (store) => (locale, key, value) => {
  if (locale === 'en-US' && key && value && !translateKeys.has(key)) {
    translateKeys.add(key);
    translatePromises.push([
      locale.slice(0, 2),
      { [key]: value },
      { headers: { 'Content-Type': 'application/json' } },
    ]);
    if (!translatingInProgress && store.getters.accessToken) {
      translatingInProgress = true;
      putTranslates(store);
    }
  }
};

export default (store) => {
  const options = {};
  if (localStorage.hasOwnProperty('translateMode')) {
    options.onTranslationNotFound = onTranslationNotFound(store);
  }
  return {
    ...defaultVuexI18nOptions,
    ...options,
  };
};
