import store from '@/store';

import { ifNotAuthorized } from '@/router/guards';

import SignIn from './views/signin/';
import ResetPassword from './views/resetPassword';

export default [
  {
    path: '/signin/:redirectTo?',
    name: 'signin',
    component: SignIn,
    beforeEnter: ifNotAuthorized(store),
  },
  {
    path: '/reset-password/:redirectTo?',
    name: 'resetPassword',
    component: ResetPassword,
    beforeEnter: ifNotAuthorized(store),
  },
  {
    path: '/signout',
    name: 'signout',
    beforeEnter(to, from, next) {
      store.dispatch('logout').then(() => next({ name: 'signin' }));
    },
  },
];
