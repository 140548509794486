<template>
  <notifications group="system" class="system" position="bottom center">
    <template slot="body" slot-scope="props">
      <div
        class="position-relative row d-flex align-items-center vue-notification"
        :class="[props.classes, props.item.type]"
      >
        <div class="col-auto flex-grow-1 ml-0 pl-0 vue-notification-content">
          {{ props.item.text }}
        </div>
        <div
          class="position-absolute close mr-0 pr-0"
          aria-label="Close"
          @click="props.close"
        >
          <AppIcon name="close" width="10" original />
        </div>
        <div>{{ props.item.data }}</div>
      </div>
    </template>
  </notifications>
</template>

<script>
import AppIcon from './Icon.vue';

export default {
  name: 'SystemNotifications',
  components: {
    AppIcon,
  },
};
</script>

<style lang="scss">
$transparency: 0.8;
.vue-notification-wrapper {
  &.system {
    display: flex;
    align-items: center;
    justify-content: center;
    width: unset !important;
    left: 50% !important;
    transform: translateX(-50%);

    .vue-notification {
      padding: 12px 24px;
      margin: 0px 0px 12px 0px;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      background: rgba($primary, $transparency);
      border: none;
      border-radius: 4px;
      &.warn {
        background: rgba($warning, $transparency);
      }
      &.error {
        background: rgba($danger, $transparency);
      }
      &.success {
        background: rgba($success, $transparency);
      }
      &-content {
        max-width: 520px;
      }
    }
  }
  .close {
    cursor: pointer;
    right: 0.4rem;
    top: 0rem;
  }
}
</style>
