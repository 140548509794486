import { DateTime } from 'luxon';
import { isFinite, isString, camelCase } from 'lodash';

export const ISOSecondsConvertion = {
  methods: {
    secondsToISO(seconds) {
      return isFinite(seconds)
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' }).toISO()
        : '';
    },
    secondsToDatetime(seconds) {
      return isFinite(seconds)
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' })
        : null;
    },
    toLocal(ISOdate) {
      return isString(ISOdate)
        ? DateTime.fromISO(ISOdate).toLocal().toISO()
        : '';
    },
    toUTC(ISOdate) {
      return isString(ISOdate) ? DateTime.fromISO(ISOdate).toUTC().toISO() : '';
    },
    ISOdateToSeconds(ISOdate) {
      return isString(ISOdate)
        ? DateTime.fromISO(ISOdate, { zone: 'UTC' }).toSeconds()
        : null;
    },
    getNow(zone = 'UTC') {
      return DateTime.fromJSDate(new Date(), { zone });
    },
    getCurrentDayStart(inISO = true) {
      return inISO
        ? DateTime.local().startOf('day').toISO()
        : DateTime.local().startOf('day');
    },
    getCurrentDayEnd(inISO = true) {
      return inISO
        ? DateTime.local().endOf('day').toISO()
        : DateTime.local().endOf('day');
    },
    secondsToFormat(seconds, format) {
      return isFinite(seconds)
        ? DateTime.fromSeconds(seconds, { zone: 'UTC' })
            .toLocal()
            .toFormat(format)
        : null;
    },
    dateFromObject(options) {
      return DateTime.fromObject(options);
    },
  },
};
const pickerHandlers = {
  methods: {
    handleDateChange(ISOdate, prop, handlerFunc) {
      if (ISOdate) {
        const ISOlimit = this.calcMinDate(prop);
        const pickedDate = DateTime.fromISO(ISOdate);
        const limit = DateTime.fromISO(ISOlimit);
        const isSameDate =
          pickedDate.day === limit.day &&
          pickedDate.month === limit.month &&
          pickedDate.year === limit.year &&
          pickedDate.hour === limit.hour &&
          pickedDate.minute === limit.minute;
        const seconds =
          isSameDate && !this.timeSteps[prop].value
            ? this.ISOdateToSeconds(this.toUTC(ISOlimit))
            : this.ISOdateToSeconds(this.toUTC(ISOdate));
        this.timeSteps[prop].value =
          isSameDate && !this.timeSteps[prop].value
            ? this.toUTC(ISOlimit)
            : this.toUTC(ISOdate);

        if (seconds) {
          handlerFunc({
            [prop]: Math.floor(seconds),
          });
          this.errors.remove(`${prop}-time`);
          this.errors.remove(prop);
        }
      }
    },
    handleDurationChange(ISOdate, prop) {
      if (ISOdate !== this.ISOtodayStart) {
        const pickedDate = DateTime.fromISO(ISOdate);
        const diff = pickedDate
          .diff(this.todayStart, ['hours', 'minutes'])
          .shiftTo('seconds');
        this[camelCase(prop)] = diff.seconds;
      }
    },
  },
};
export default [ISOSecondsConvertion, pickerHandlers];
