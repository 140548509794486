import { DateTime } from 'luxon';

const APP_START_TIME = DateTime.fromObject({
  year: 2017,
  month: 1,
  day: 1,
  zone: 'UTC',
});
const TODAY_START = DateTime.utc().startOf('day');
const TODAY_END = DateTime.utc().endOf('day');

const floor = ({ from, to, ...args }) => ({
  from: Math.floor(from),
  to: Math.floor(to),
  ...args,
});

export default {
  lifetime() {
    const from = APP_START_TIME.toSeconds();
    const to = TODAY_START.toSeconds();
    const boundary = 'months';
    return floor({ from, to, boundary });
  },
  today() {
    const from = TODAY_START.minus({ days: 1 }).toSeconds();
    const to = TODAY_END.toSeconds();
    const boundary = 'days';
    return floor({ from, to, boundary });
  },
  yesterday() {
    const from = TODAY_START.minus({ days: 2 }).toSeconds();
    const to = TODAY_END.minus({ days: 1 }).toSeconds();
    const boundary = 'days';
    return floor({ from, to, boundary });
  },
  last_7_days() {
    const from = TODAY_START.minus({ days: 7 }).toSeconds();
    const to = TODAY_START.toSeconds();
    const boundary = 'days';
    return floor({ from, to, boundary });
  },
  last_28_days() {
    const from = TODAY_START.minus({ days: 28 }).toSeconds();
    const to = TODAY_START.toSeconds();
    const boundary = 'days';
    return floor({ from, to, boundary });
  },
  last_90_days() {
    const from = TODAY_START.minus({ days: 90 }).toSeconds();
    const to = TODAY_START.toSeconds();
    const boundary = 'days';
    return floor({ from, to, boundary });
  },
  last_year() {
    const from = TODAY_START.minus({ years: 1 }).toSeconds();
    const to = TODAY_START.toSeconds();
    const boundary = 'months';
    return floor({ from, to, boundary });
  },
};
