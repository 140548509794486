<template>
  <div
    class="position-relative"
    :class="{ 'is-invalid': error }"
    :aria-invalid="!!error"
  >
    <div class="dropdown" :class="[{ 'is-disabled': disabled }, setVariant]">
      <label
        v-if="showLabel"
        class="d-flex"
        :class="{ 'label-required-field': required && !viewMode }"
      >
        <TextBlock
          :size="0.75"
          :line-height="1"
          :variant="labelVariant"
          :text="label"
        />
      </label>
      <button
        type="button"
        :disabled="disabled || viewMode"
        class="btn dropdown-toggle"
        :class="[variant === 'button-outline' ? 'hera-btn' : 'border-0']"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <TextBlock
          :variant="variant === 'primary-button' ? 'light' : 'primary'"
          class="chosen-item"
        >
          <span v-if="isEmpty" class="placeholder-text">
            {{ getValue }}
          </span>
          <slot v-else :chosenItem="chosenItem" name="chosen">
            <span class="chosen-item">{{ getValue }}</span>
          </slot>
        </TextBlock>
        <icon
          name="dropdown"
          class="dropdown-icon"
          width="18"
          :fill="variant !== 'primary-button' ? $scss.textColor : 'white'"
        />
      </button>

      <div class="dropdown-menu">
        <input
          v-if="showFilter"
          id="dropdownFilter"
          v-model.trim="dropdownFilter"
          type="text"
          class="form-control shadow-none mb-2 border-top-0 border-left-0 border-right-0 rounded-0"
          :class="searchIcon"
          :placeholder="filterPlaceholder"
          name="dropdownFilter"
          autocomplete="off"
        />

        <div class="custom-scrollbar">
          <a
            v-if="emptyOption && emptyText && couldBeEmpty"
            :class="{ disabled: !couldBeEmpty }"
            class="dropdown-item"
            @click.prevent="couldBeEmpty && changeItem(null)"
          >
            {{ emptyText }}
          </a>

          <a
            v-for="(item, index) in filteredList"
            :key="index"
            class="dropdown-item"
            @click.prevent="changeItem(item)"
          >
            <slot :item="item" name="item">
              {{ item.text }}
            </slot>
          </a>
        </div>
      </div>
    </div>

    <!--TODO - TH-7110 rework with popover-->
    <div v-if="tooltip.length" class="position-absolute control-tooltip">
      <InfoTooltip :text="tooltip" />
    </div>

    <TextBlock
      v-if="error"
      variant="error"
      :size="0.75"
      :text="error"
      class="form-text d-block mb-1"
    />
  </div>
</template>
<script>
import icon from '@/components/common/Icon';
import TextBlock from '@/components/text/TextBlock';
import InfoTooltip from '@/components/common/InfoTooltip';

export default {
  name: 'HeraFormDropdown',
  components: {
    icon,
    TextBlock,
    InfoTooltip,
  },

  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator: (val) => {
        return (
          [
            'default',
            'input-like',
            'flat-dark',
            'view',
            'primary-button',
            'button-outline',
          ].indexOf(val) !== -1
        );
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    emptyOption: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'Open to select...',
    },
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: '',
    },
    label: {
      type: String,
      default: 'Choose option',
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    couldBeEmpty: {
      type: Boolean,
      default: false,
    },
    dir: {
      type: String,
      default: 'ltr',
    },
    searchIcon: {
      type: String,
      default: '',
    },
    filterPlaceholder: {
      type: String,
      default:
        "$t('_web_common_input_filter_placeholder', 'Type to filter list...')",
    },
    tooltip: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chosenItem: this.value,
      dropdownFilter: '',
      isEmpty: this.emptyOption,
    };
  },

  computed: {
    filteredList() {
      return this.list.filter((e) => {
        return this.showFilter && this.dropdownFilter
          ? e.name.toLowerCase().indexOf(this.dropdownFilter.toLowerCase()) > -1
          : true;
      });
    },
    labelVariant() {
      return this.error ? 'error' : 'secondary';
    },
    getValue() {
      return typeof this.chosenItem === 'object' &&
        this.chosenItem.hasOwnProperty('text')
        ? this.chosenItem.text
        : this.chosenItem;
    },
    setVariant() {
      return this.viewMode ? 'dropdown--view' : `dropdown--${this.variant}`;
    },
  },
  watch: {
    value: function (newVal) {
      if (newVal) {
        this.chosenItem = newVal;
        this.isEmpty = false;
      }
    },
  },
  created() {
    if (this.value) {
      this.chosenItem = this.value;
      this.isEmpty = false;
    } else if (this.emptyOption) {
      this.chosenItem = this.emptyText;
    } else {
      this.chosenItem = this.list[0];
      this.isEmpty = false;
    }
  },

  updated() {
    if (!this.value) {
      this.chosenItem = this.emptyText;
      this.isEmpty = true;
    } else {
      this.chosenItem = this.value;
      this.isEmpty = false;
    }
  },
  methods: {
    changeItem(item) {
      this.isEmpty = !item;
      this.chosenItem = item || this.emptyText;
      this.$emit('itemChanged', item);
      this.$emit('change', item);
      this.$emit('input', item);
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  transition: all 0.3s ease-in-out;

  label {
    margin: 0;
    line-height: 1;
  }

  &:focus-within {
    label,
    label * {
      color: $primary;
    }
  }

  .dropdown-toggle {
    padding-left: 0.25rem;
    padding-right: 1.2rem;
    background-color: transparent;
    line-height: 1.6;
    outline: none;
    box-shadow: none;
    position: relative;
    min-width: unset;
    color: #fff;

    &:after {
      content: none;
      border: none;
    }

    .placeholder-text {
      color: #898b9b;
    }

    .chosen-item {
      color: #fff;
    }

    .dropdown-icon {
      position: absolute;
      top: calc(50% - 7px);
      cursor: pointer;
      @include auto-rtl(right, 0.125rem);
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  .dropdown-item {
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    line-height: 1.375;

    &:hover {
      cursor: pointer;
      background-color: $hera-secondary-card-bg;
    }
  }

  .dropdown-menu {
    max-height: 250px;
    min-width: auto;
    width: 100%;
    height: auto;
    background-color: $ph-card;
    font-weight: normal;
    color: $ph-primary-text;

    & > div {
      overflow: auto;
    }

    input.form-control {
      background: none;
      border-color: $panel-bg-color;

      &::placeholder {
        color: $muted;
      }

      &.search-icon {
        background: url('~@/assets/images/icons/search-mute.svg') no-repeat left
          1rem center;
        padding-left: 3rem;
      }
    }

    &[x-placement='bottom-start'] {
      &:after {
        position: absolute;
        content: '';
        width: 11px;
        height: 11px;
        transform: rotate(45deg);
        border-radius: 3px;
        top: -4px;
        left: 0.55rem;
        background-color: $ph-card;
      }
    }

    &[x-placement='top-start'] {
      &:after {
        position: absolute;
        content: '';
        width: 11px;
        height: 11px;
        transform: rotate(45deg);
        border-radius: 3px;
        bottom: -4px;
        left: 0.55rem;
        background-color: $ph-card;
      }
    }

    &.show {
      display: flex;
      flex-direction: column;

      & > div {
        flex-grow: 1;
      }
    }
  }

  &.is-disabled {
    * {
      pointer-events: none;
    }

    border: none !important;

    .dropdown-toggle {
      * {
        color: $text-secondary-color !important;
      }
      border-radius: 0;
      opacity: 0.6 !important;
      border-bottom: 1px dashed $text-secondary-color !important;
    }
  }

  &--input-like {
    .dropdown-toggle {
      width: 100%;
      background-position: 95% center;
      text-align: left;
      @include auto-rtl(padding-left, 0.25rem);
      outline: none;
      border-radius: 0;
      border-bottom-width: 1px !important;
      border-bottom-style: solid !important;
      border-bottom-color: $text-secondary-color !important;

      &:focus {
        border-color: $primary !important;
        box-shadow: none !important;
      }
    }

    &.w-auto {
      .dropdown-toggle {
        width: auto;
      }
      .dropdown-menu {
        width: auto;
      }
    }

    &.is-disabled {
      border: none;

      * {
        pointer-events: none;
      }

      .dropdown-toggle {
        border-radius: 0;
        opacity: 0.6 !important;
        border-bottom: 1px dashed $text-secondary-color !important;

        * {
          color: $text-secondary-color !important;
        }
      }
    }

    &.is-invalid {
      .dropdown-toggle {
        border-bottom-color: $danger !important;
      }
    }
  }

  &--flat-dark {
    // will be add soon
  }

  &--primary-button {
    background: $primary;
    padding: 0 0.5rem;
    border-radius: 0.5rem !important;
    .dropdown-toggle {
      width: 100%;
      background-position: 95% center;
      text-align: left;
      @include auto-rtl(padding-left, 0.25rem);
      outline: none;

      &:focus {
        border-color: $primary !important;
        box-shadow: none !important;
      }
    }
  }

  &--button-outline {
    background: transparent;
    .dropdown-toggle {
      padding: 0 1.5rem 0 0.75rem;
      width: 100%;
      background-position: 95% center;
      text-align: left;
      outline: none;
      &:focus {
        border-color: $primary !important;
        box-shadow: none !important;
      }
      .dropdown-icon {
        @include auto-rtl(right, 0.25rem);
      }
    }
  }

  &--view {
    * {
      pointer-events: none;
    }

    .dropdown-toggle {
      opacity: 1;
      padding-left: 0;
    }

    .dropdown-icon {
      display: none;
    }
  }
}

.control-tooltip {
  @include auto-rtl(right);
  top: -0.25rem;
}
</style>
