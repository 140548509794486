import api from '@/api';
import Vue from 'vue';

const state = {
  countries: JSON.parse(localStorage.getItem('countries')) || [],
  // translations: {},
  platforms: [],
  games: [],
  genders: ['Male', 'Female'],
};

const getters = {
  countries: (state) => state.countries,
  countryByCode: (state) => (code) =>
    state.countries.find((el) => el.code === code),
  platforms: (state) =>
    state.platforms.map((p) => ({
      id: p.id,
      logo: p.logo,
      name: p.name,
    })),
  games: (state) => state.games,
  publishedGames: (state) => state.games.filter((game) => game.published),
  gamesByPlatform: (state) => (platformId) => {
    let platform = state.platforms.find((el) => el.id === platformId);
    return platform ? platform.games : [];
  },
  genders: (state) => state.genders,
  getPlatformLogoById: (state) => (platformId) => {
    const platform = state.platforms.find((item) => {
      return item.id === platformId;
    });

    return platform ? platform.logo : '';
  },
  getGamePropById: (state) => (gameId, prop) => {
    const game = state.games.find((item) => {
      return item.id === gameId;
    });

    return game ? game[prop] || '' : '';
  },
  getPlatformPropById: (state) => (platformId, prop) => {
    const platform = state.platforms.find((item) => {
      return item.id === platformId;
    });

    return platform ? platform[prop] || '' : '';
  },
  platformByID: (state) => (ID) => state.platforms.find((el) => el.id === ID),
  gameByID: (state) => (ID) => state.games.find((el) => el.id === ID),
};

const mutations = {
  setCountries(state, payload = []) {
    state.countries = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.countries, i, {});
      state.countries[i] = { ...state.countries[i], ...payload[i] };
    }
    // TODO: use setObjectToItem util here https://isddesign.atlassian.net/browse/TH-7201
    localStorage.setItem('countries', JSON.stringify(payload));
  },
  setPlatforms(state, payload) {
    state.platforms = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.platforms, i, {});
      state.platforms[i] = { ...state.platforms[i], ...payload[i] };
    }
    // TODO: think about caching platforms list to reduce initial app loading https://isddesign.atlassian.net/browse/TH-7200
    // localStorage.setItem('platforms', JSON.stringify(payload));
  },
  setGames(state, payload) {
    state.games = [];
    for (var i = 0; i < payload.length; i += 1) {
      Vue.set(state.games, i, {});
      state.games[i] = { ...state.games[i], ...payload[i] };
    }
    // TODO: think about caching games list to reduce initial app loading https://isddesign.atlassian.net/browse/TH-7199
    // localStorage.setItem('games', JSON.stringify(payload));
  },
  addLocaleToStore(state, payload) {
    let keys = Object.keys(payload.data);
    keys.forEach((key) => {
      if (!state.translations[key]) {
        Vue.set(state.translations, key, {});
      }
      Vue.set(state.translations[key], payload.locale, payload.data[key]);
    });
  },
  setGenders(state, payload) {
    state.genders = payload;
  },
};
const actions = {
  fetchCallingCodes({ commit, dispatch }) {
    api.settings
      .getCallingCodes()
      .then((data) => commit('setCountries', data.data))
      .catch((error) => {
        dispatch('errorNotify', error);
      });
  },
  fetchPlatforms({ commit, getters: { platforms, accessToken } }) {
    return !platforms.length
      ? api.settings
          .getPlatforms(accessToken)
          .then((data) => commit('setPlatforms', data.data))
      : Promise.resolve();
  },
  fetchGames({ commit, getters: { games, accessToken } }) {
    return !games.length
      ? api.settings
          .getGames(true)(accessToken)
          .then((data) => commit('setGames', data.data))
      : Promise.resolve();
  },
  suggestCountriesRequest(moduleState, query) {
    return api.settings
      .suggestCountries(query)()
      .then((response) => response.data);
  },
  suggestCitiesRequest(moduleState, payload) {
    return api.settings
      .suggestCities(payload.countryId, payload.query)()
      .then((response) => response.data);
  },
  fetchClans({ getters: { accessToken } }, params) {
    return api.clans.getClans(accessToken, params).then((res) => res.data);
  },
  fetchMyClans({ dispatch }) {
    return dispatch('fetchClans', { profile: 'my' });
  },
  inviteUserToMyClan({ getters: { accessToken }, dispatch }, login) {
    return new Promise((resolve, reject) => {
      dispatch('fetchMyClans')
        .then((response) =>
          response && response.items.length
            ? api.clans.inviteClanMember(accessToken, response.items[0].id, [
                login,
              ])
            : reject(new Error('You have no clan'))
        )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  cancelUserFromMyClan({ getters: { accessToken } }, { clanID, login }) {
    return new Promise((resolve, reject) => {
      api.clans.cancelClanMember(accessToken, clanID, login).then(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  },

  // getTranslationsByLocale ({commit}, locale) {
  //   let localeArray = []
  //   if(locale.length) {
  //     localeArray = [...locale]
  //   } else {
  //     localeArray.push(locale)
  //   }

  //   let localePromises = localeArray.map((el,i) => api
  //     .getTranslationByLocale(el)().catch(error=>{ return error })
  //     )

  //   return Promise.all(localePromises)
  //     .then(
  //       responseArray =>{
  //         // console.log(responseArray)
  //         return new Promise((resolve) => responseArray.forEach(
  //             (data, i) => {
  //               commit(
  //                 'addLocaleToStore',
  //                 {locale: localeArray[i], data: data.data.translations}
  //               )
  //               resolve()
  //             })
  //           )
  //         }
  //       )
  //     .catch(error => {
  //       console.log('error getting translations')
  //       console.log(error)
  //     })
  // },
  // saveTranslation ({commit, getters}, translation) {
  //   commit('updateTranslation', translation)
  //   return api
  //     .putTranslation(
  //       translation.locale,
  //       translation.key)(
  //       getters.accessToken,
  //       translation.value,
  //       {headers: {"Content-Type": "application/json"}})
  //     .then(
  //       data => Promise.resolve(data),
  //       error => Promise.reject(error)
  //     )
  //     .catch(error => {
  //       console.log('error getting translations')
  //       console.log(error)
  //     })
  // }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
