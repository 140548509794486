<template>
  <div class="w-100">
    <Title text="Scheduled Tournaments (Monthly)" class="mb-2" />
    <Calendar />
    <hr class="my-4 border-0 bg-secondary" style="height: 1px" />
    <UserActivitySection />
  </div>
</template>

<script>
import UserActivitySection from '../components/userActivity';
import Calendar from '@/components/dashboard/calendar';
import Title from '@/components/text/Title';
export default {
  name: 'Dashboard',
  components: {
    UserActivitySection,
    Calendar,
    Title,
  },
};
</script>
<style lang="scss">
@import '~tui-chart/dist/tui-chart.css';
.tui-line-chart {
  path.auto-shape-rendering {
    stroke-width: 2;
  }
}
</style>
