import Vue from 'vue';
import Vuex from 'vuex';

import user from '@/storeModules/user.js';
import commonData from '@/storeModules/commonData.js';
import state from '@/storeModules/appState.js';
import notification from '@/storeModules/notification.js';
import modal from '@/storeModules/modal.js';
import popoverMenu from '@/storeModules/popoverMenu.js';
import calendar from '@/storeModules/phcpCalendar';
import globalSettings from '@/storeModules/globalSettings';
import metrics from '@/storeModules/phcpMetrics';
import myProfile from '@/storeModules/phcpMyProfile';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    commonData,
    state,
    notification,
    modal,
    popoverMenu,
    calendar,
    globalSettings,
    metrics,
    myProfile,
  },
  strict: process.env.NODE_ENV !== 'production',
});
