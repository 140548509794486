import { cloneDeep, isEqual, isObject, reduce, get, set } from 'lodash';

const difference = function (obj1, obj2, path, depth = Infinity) {
  obj1 = obj1 || {};
  obj2 = obj2 || {};

  let startObj, compareObj;

  if (Object.keys(obj1).length > Object.keys(obj2).length) {
    startObj = cloneDeep(obj1);
    compareObj = cloneDeep(obj2);
  } else {
    startObj = cloneDeep(obj2);
    compareObj = cloneDeep(obj1);
  }

  return reduce(
    startObj,
    (result, value, key) => {
      let p = path ? path + '.' + key : key;
      if (depth !== 0 && isObject(value)) {
        let d = difference(value, compareObj[key], p, depth - 1);
        return d.length ? result.concat(d) : result;
      }
      return isEqual(value, compareObj[key]) ? result : result.concat(p);
    },
    []
  );
};

export const getObjectDifference = function (newObj, prevObj, depth) {
  const diff = difference(newObj, prevObj, undefined, depth);
  return diff.reduce((obj, key) => {
    let prop = cloneDeep(get(newObj, key));
    if (prop) {
      set(obj, key, prop);
    }
    return obj;
  }, {});
};

export const diff = {
  methods: {
    diff: difference.bind(this),
    getObjectDifference: getObjectDifference.bind(this),
  },
};

export default {
  diff,
};
