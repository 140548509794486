<template>
  <a v-bind="{ href, style }" @click.prevent="$emit('click')">
    <slot v-bind="{ time: timeLeft }" />
  </a>
</template>

<script>
import { prop } from 'playhera-utils/factories';

export default {
  props: {
    href: prop(String, '#'),
    timeout: prop(Number, 30), // s
  },
  data() {
    return {
      timeLeft: 0,
      timer: null,
    };
  },
  computed: {
    style() {
      if (this.timeLeft > 0) {
        return {
          cursor: 'not-allowed',
          pointerEvents: 'none',
        };
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.start();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  methods: {
    start() {
      this.timeLeft = this.timeout;
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
};
</script>
