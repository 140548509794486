'use strict';

import locale from 'browser-locale';

// TODO - TH-7076 delete fr and ko lnguages
export const AVAILABLE_LOCALES = [
  {
    key: 'ar-AE',
    title: 'Arabic',
    nativeTitle: 'ﻰﺑﺮﻋ',
    logo: 'ar.png',
    direction: 'rtl',
  },
  {
    key: 'en-US',
    title: 'English',
    nativeTitle: 'English',
    logo: 'gb.png',
    direction: 'ltr',
  },
  {
    key: 'ja-JP',
    title: 'Japanese',
    nativeTitle: '日本語',
    logo: 'ja.png',
    direction: 'ltr',
  },
];
export const USER_LOCALE = locale();
export const WE_HAVE_USER_LOCALE = AVAILABLE_LOCALES.find((local) => {
  return local.key[0].substr(0, 2) === USER_LOCALE.substr(0, 2);
});

export default {
  AVAILABLE_LOCALES,
  USER_LOCALE,
  WE_HAVE_USER_LOCALE,
};
