import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

const COMPETITIONS = 'competitions';

export default {
  getByID: (competitionID) =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}`),
  getParticipants: (competitionID) =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/players`),
  getRounds: (competitionID) =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/rounds`),
  forceStartRound: (tournamentID, stageID, competitionID, roundID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/rounds/${roundID}/start`
    ),
  forceFinishRound: (tournamentID, stageID, competitionID, roundID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/rounds/${roundID}/finish`
    ),
  updateRound: (tournamentID, stageID, competitionID, roundId) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/rounds/${roundId}`
    ),
  getMatches: (competitionID) =>
    authorizedGetRequest(`${COMPETITIONS}/${competitionID}/matches`, {
      cancelStrategy: 'forward',
    }),
  updateMatchByID: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}`
    ),
  provideResultByAdmin: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPostRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/results`
    ),
  forceStartMatch: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/start`
    ),
  forceFinishMatch: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/finish`
    ),
  resetMatch: (tournamentID, stageID, competitionID, matchID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/matches/${matchID}/reset`
    ),
  moveMatchToRound: (competitionID, destinationRoundID) =>
    authorizedPostRequest(
      `${COMPETITIONS}/${competitionID}/matches/rounds/${destinationRoundID}`
    ),
  swapPlayers: (tournamentID, stageID, competitionID) =>
    authorizedPutRequest(
      `tournaments/${tournamentID}/stages/${stageID}/blocks/${competitionID}/swap_matches_players`
    ),
};
