<template>
  <TextBlock
    v-bind="{
      size,
      variant,
      lineHeight,
      text,
      tag,
    }"
  >
    <slot />
  </TextBlock>
</template>

<script>
import { prop } from 'playhera-utils';
import TextBlock from './TextBlock';

export default {
  name: 'Title',
  components: {
    TextBlock,
  },
  props: {
    text: prop(String, ''),
    tag: prop(String, 'h1'),
    size: prop(Number, 1.5), // rem
    variant: prop(String, 'primary'),
    lineHeight: prop([Number, String], 1.25),
  },
};
</script>
