<template>
  <h4 class="hera-subheader" :class="[`hera-subheader--${variant}`]">
    <TextBlock
      v-bind="{
        size: 1.125,
        variant: isMuted ? 'muted' : 'primary',
        lineHeight: 1.333334,
        text,
        tag,
      }"
    />
  </h4>
</template>

<script>
import TextBlock from './TextBlock';

export default {
  name: 'Subheader',
  components: {
    TextBlock,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      validator(val) {
        return ['strong', 'em', 'normal', 'muted'].indexOf(val) !== -1;
      },
      default: 'normal',
    },
  },
  computed: {
    tag() {
      return this.variant === 'normal' || this.variant === 'muted'
        ? 'span'
        : this.variant;
    },
    isMuted() {
      return this.variant === 'muted';
    },
  },
};
</script>

<style lang="scss">
.hera-subheader {
  &--strong {
    * {
      font-weight: bold;
    }
    // fill styles for strong variant of the Title here
  }
  &--em {
    // fill styles for em variant of the Title here
  }
  &--normal {
    // fill styles for normal variant of the Title here
  }
}
</style>
