<template>
  <div id="app" class="position-relative">
    <component :is="layout" v-if="hasLayout">
      <router-view />
    </component>
    <router-view v-else />
    <OfflinePopup />
    <systemNotification class="blur-none" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import systemNotification from '@/components/common/SystemNotification.vue';
import OfflinePopup from '@/components/common/OfflinePopup';

export default {
  name: 'App',
  components: {
    systemNotification,
    OfflinePopup,
  },
  computed: {
    ...mapGetters(['isAuthorized', 'login', 'isRTL']),
    hasLayout() {
      return !!this.$route.meta.layout;
    },
    layout() {
      return `${this.$route.meta.layout}-layout`;
    },
  },
  watch: {
    isRTL() {
      this.setDirectionClassToBody();
    },
  },
  created() {
    this.setDirectionClassToBody();
  },
  methods: {
    setDirectionClassToBody() {
      const classListBody = document.body.classList;

      classListBody.toggle(this.$scss['dirClasses_rtl'], this.isRTL);
      classListBody.toggle(this.$scss['dirClasses_ltr'], !this.isRTL);

      document.documentElement.setAttribute('dir', this.isRTL ? 'rtl' : 'ltr');
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/phcp/app.scss';
</style>
