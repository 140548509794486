<template>
  <input class="_text-input" v-on="handlers" />
</template>

<script>
// Expiremental solution. Need to implement RTL.

import { prop } from 'playhera-utils';
import valueModel from '../mixins/valueModel';

export default {
  name: 'TextInput',
  mixins: [valueModel],
  props: {
    value: prop(String),
  },
  computed: {
    handlers() {
      return {
        ...this.$listeners,
        input: this.inputHandler,
      };
    },
  },
  methods: {
    inputHandler(e) {
      this.valueModel = e.target.value;
    },
  },
};
</script>

<style lang="scss">
$ph-input-padding: 0.375rem 0.5rem !default;
$ph-input-font-size: 0.75rem !default;
$ph-input-font-weight: 400 !default;
$ph-input-line-height: 1.5 !default;
$ph-input-color: $ph-primary-text !default;
$ph-input-border: none !default;
$ph-input-border-radius: 0.25rem !default;

._text-input {
  display: block;
  width: 100%;
  padding: $ph-input-padding;
  font-size: $ph-input-font-size;
  font-weight: $ph-input-font-weight;
  line-height: $ph-input-line-height;
  color: $ph-input-color;
  background-color: $ph-input;
  border: $ph-input-border;
  border-radius: $ph-input-border-radius;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    color 6000s, background-color 6000s;

  &:focus {
    border-color: $ph-accent;
    box-shadow: inset 0 1px 1px rgba($ph-accent, 0.075),
      0 0 0 0.2rem rgba($ph-accent, 0.25);
  }

  &--is-invalid {
    border-color: $ph-error;
    &:focus {
      box-shadow: inset 0 1px 1px rgba($ph-error, 0.075),
        0 0 0 0.2rem rgba($ph-error, 0.25);
    }
  }
}
</style>
