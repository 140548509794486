export const ACCOUNT = 'account';
export const PROFILE = 'profile';
export const ORGANIZATION = 'organization';
export const CLAN = 'clan';
export const TOURNAMENT = 'tournament';

export const COUNT_FOLLOWERS = 'followers';
export const COUNT_FOLLOWING = 'following';
export const COUNT_FRIENDS = 'friends';
export const COUNT_MEMBERS = 'members';

export const COUNT_BY_TYPES = {
  [PROFILE]: [COUNT_FOLLOWERS, COUNT_FOLLOWING, COUNT_FRIENDS],
  [ORGANIZATION]: [COUNT_FOLLOWERS, COUNT_MEMBERS],
  [CLAN]: [COUNT_FOLLOWERS, COUNT_MEMBERS],
  [TOURNAMENT]: [],
};

export const ORGANIZATION_TYPES = [
  'hera_admin',
  'federation',
  'media',
  'hardware_developer',
  'event_organizer',
];

export default {
  ACCOUNT,
  PROFILE,
  ORGANIZATION,
  ORGANIZATION_TYPES,
  CLAN,
  TOURNAMENT,

  COUNT_BY_TYPES,
  COUNT_FOLLOWERS,
  COUNT_FOLLOWING,
  COUNT_FRIENDS,
  COUNT_MEMBERS,
};
