<template>
  <div class="user-activity">
    <Title text="Overview totals" class="mb-2" />

    <TextBlock variant="secondary" :size="0.875">Time:</TextBlock>
    <Dropdown
      v-model="period"
      variant="primary-button"
      :list="statisticPeriodsKeys"
      class="mb-3"
      @input="() => fetchMetrics()"
    >
      <template slot="chosen" slot-scope="{ chosenItem }">
        <TextBlock variant="light" :size="0.875">{{
          chosenItem | startCase
        }}</TextBlock>
      </template>
      <template slot="item" slot-scope="{ item }">
        <TextBlock :size="0.875">{{ item | startCase }}</TextBlock>
      </template>
    </Dropdown>

    <div class="row mb-3">
      <div
        v-for="counter in visibleCounters"
        :key="`counter-${counter.label}`"
        class="col-xl-4 col-md-6 col-sm-12 pb-4"
      >
        <StatisticPanel
          :label="counter.label"
          :total="counter.total"
          :options="counter.options"
          :variant="
            counter.label === activeCounter.label ? 'transparent' : 'hover'
          "
          :is-loading="counter.isLoading"
          :has-error="counter.hasError"
          class="cursor-hand h-100"
          :class="[
            counter.label === activeCounter.label
              ? 'border-primary'
              : 'border-transparent',
          ]"
          @click.native="updateChartData(counter)"
        />
      </div>
    </div>
    <Subheader :text="activeCounter.label" class="mb-3" />
    <div ref="chartWrapper">
      <line-chart
        :key="lineChartRenderKey"
        :data="lineChartData"
        :options="lineChartOptions"
        :theme="theme"
      ></line-chart>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Title from '@/components/text/Title';
import Subheader from '@/components/text/Subheader';
import { lineChart } from '@toast-ui/vue-chart';
import chartThemeFactory from '../utils/chartThemeFactory';
import StatisticPanel from './statisticPanel';
import LoadingMixin from '@/mixins/isLoading';
import { ISOSecondsConvertion } from '@/mixins/dateMethods';
import TextBlock from '@/components/text/TextBlock';
import Dropdown from '@/components/form/HeraFormDropdown';
import statisticPeriods from '../utils/statisticPeriods';

const HIDDEN_WIDGETS = ['active_users', 'ongoing_leagues'];
const METRICS = [
  'registered_users',
  'active_users',
  'organizers',
  'completed_tournaments',
  'ongoing_leagues',
  'matches',
  'teams',
  'games',
  'genres',
];

export default {
  name: 'UserActivitySection',
  components: {
    StatisticPanel,
    Title,
    Subheader,
    lineChart,
    TextBlock,
    Dropdown,
  },
  mixins: [LoadingMixin, ISOSecondsConvertion],
  data() {
    return {
      period: null,
      activeCounter: {},
      theme: chartThemeFactory(this.$scss),
      metrics: [...METRICS],
      counters: [],
      lineChartRenderKey: Date.now(),
      lineChartData: {},
      lineChartOptions: {
        chart: {
          width: 360,
          height: 360,
        },
        legend: {
          visible: true,
          align: 'bottom',
        },
        chartExportMenu: {
          visible: false,
        },
        tooltip: {
          grouped: true,
        },
        series: {
          spline: true,
        },
      },
    };
  },
  computed: {
    ...mapState({
      regions: (state) => state.globalSettings.regions,
    }),
    statisticPeriodsKeys() {
      return Object.keys(statisticPeriods);
    },
    periodOptions() {
      return statisticPeriods[this.period]();
    },
    visibleCounters() {
      const filtredKeys = Object.keys(this.counters).filter(
        (key) => !HIDDEN_WIDGETS.includes(key)
      );
      const counters = {};
      filtredKeys.forEach((key) => (counters[key] = this.counters[key]));
      return counters;
    },
    currentDateFormatToken() {
      return this.periodOptions.boundary === 'months'
        ? 'LLLL'
        : this.periodOptions.boundary === 'years'
        ? 'y'
        : 'D';
    },
  },
  created() {
    this.$options.todayStart = this.getCurrentDayStart(false);
    this.period = this.statisticPeriodsKeys[0];
    this.counters = {
      ...this.metrics.reduce(
        (acc, metricName) => ({
          ...acc,
          [metricName]: {
            label: this.$lodash.startCase(metricName),
            labelKey: metricName,
            hasError: false,
            isLoading: true,
          },
        }),
        {}
      ),
      regions: {
        label: 'Regions',
        labelKey: 'regions',
        hasError: false,
        isLoading: true,
      },
    };
    this.fetchMetrics();
  },
  mounted() {
    this.setChartWidth();
    window.addEventListener('resize', this.setChartWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setChartWidth);
  },
  methods: {
    ...mapActions(['getCoreStatisticsMetrics', 'errorNotify']),
    updateChartData(counter) {
      if (counter.options) {
        this.activeCounter = counter;
        this.lineChartData = this.prepareOptions(counter.options);
        this.lineChartRenderKey = Date.now();
      }
    },
    setChartWidth() {
      this.$nextTick(() => {
        this.$set(
          this.lineChartOptions.chart,
          'width',
          this.$refs.chartWrapper.clientWidth
        );
        this.lineChartRenderKey = Date.now();
      });
    },
    prepareOptions(options) {
      // contact @olsy to find out what this code does
      const allTimes = this.$lodash
        .uniq(
          this.$lodash.flatten(
            options.map(({ series }) => series.map(({ date }) => date))
          )
        )
        .sort((a, b) => a - b);

      const series = options.map(({ key, series }) => {
        series.forEach(({ date }, index) => {
          if (allTimes[index] !== date) {
            series.splice(index, 0, {
              date,
              value: 0,
            });
          }
        }, []);

        return {
          name: key,
          data: series.map(({ value }) => value),
        };
      });

      const categories = allTimes.map((time) =>
        this.secondsToFormat(time, this.currentDateFormatToken)
      );

      return {
        series,
        categories,
      };
    },
    fetchMetrics(metricNames = [...METRICS]) {
      const options = this.periodOptions;

      this.counters = metricNames.reduce((acc, metricName) => {
        acc[metricName] = {
          ...acc[metricName],
          isLoading: true,
        };
        return acc;
      }, this.$lodash.cloneDeep(this.counters));
      this.getCoreStatisticsMetrics({
        ...options,
        metricnames: metricNames,
      })
        .then((response) => {
          const metrics = response.metrics.reduce((acc, el) => {
            acc[el.metricName] = el;
            return acc;
          }, {});
          return metricNames.reduce(
            (acc, metricName) => {
              if (!this.$lodash.isEmpty(metrics[metricName])) {
                acc[metricName] = {
                  label: this.$lodash.startCase(metricName),
                  labelKey: metricName,
                  total: metrics[metricName].summary.reduce(
                    (acc, el) => acc + el.total,
                    0
                  ),
                  options: metrics[metricName].summary.map((el) => ({
                    key: this.regions[el.region].name,
                    value: el.total,
                    previousValue: el.diff,
                    series: metrics[metricName].points[el.region],
                  })),
                };
              }
              return acc;
            },
            {
              regions: {
                label: 'Regions',
                labelKey: 'regions',
                total: response.regionCount,
              },
            }
          );
        })
        .then((counters) => {
          const fetchedMetricsNames = Object.keys(counters);
          metricNames = metricNames.concat(['regions']); // add static section
          this.counters = metricNames.reduce((acc, metricName) => {
            acc[metricName] = {
              ...acc[metricName],
              ...counters[metricName],
              hasError: !fetchedMetricsNames.includes(metricName),
              isLoading: false,
            };
            return acc;
          }, this.$lodash.cloneDeep(this.counters));
          if (this.$lodash.isEmpty(this.activeCounter)) {
            this.activeCounter = this.counters[this.metrics[0]];
          } else {
            const currentActiveCounterKey = this.activeCounter.labelKey;
            this.activeCounter = this.counters[currentActiveCounterKey];
          }
          this.updateChartData(this.activeCounter);
        })
        .catch((error) => {
          this.errorNotify(error);
          this.counters = metricNames.reduce((acc, metricName) => {
            acc[metricName] = {
              ...acc[metricName],
              hasError: true,
              isLoading: false,
            };
            return acc;
          }, this.$lodash.cloneDeep(this.counters));
        });
    },
  },
};
</script>

<style lang="scss">
.user-activity {
  .dropdown {
    width: 9rem;
  }
  .dropdown .dropdown-menu::after {
    left: unset;
    right: 1.25rem;
  }
  .dropdown .dropdown-toggle {
    $py: 0.25rem;
    padding-top: $py;
    padding-bottom: $py;
  }
}
</style>
