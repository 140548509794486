import { authorizedPostRequest, simplePostRequest } from '@/api/requests';

const AUTH_BASE = 'auth';

const signIn = simplePostRequest(`${AUTH_BASE}/signin`);
const signUp = simplePostRequest(`${AUTH_BASE}/signup`);
const signOut = authorizedPostRequest(`${AUTH_BASE}/signout`);
const sendCode = authorizedPostRequest(`${AUTH_BASE}/sendcode`);
const verifyCode = authorizedPostRequest(`${AUTH_BASE}/verify`);
const resetPass = simplePostRequest(`${AUTH_BASE}/reset_password`);
const submitPass = authorizedPostRequest(`${AUTH_BASE}/submit_password`);
const refreshToken = authorizedPostRequest(`${AUTH_BASE}/refresh`);
const switchAccount = authorizedPostRequest(`${AUTH_BASE}/switch`);

export default {
  signIn,
  signUp,
  signOut,
  sendCode,
  verifyCode,
  resetPass,
  submitPass,
  refreshToken,
  switchAccount,
};
