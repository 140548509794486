import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
} from '@/api/requests';

const COMMENTS = 'comments';

export const getComments = (accessToken, { type, id, page, page_size }) =>
  authorizedGetRequest(`${COMMENTS}/${type}/${id}`)(accessToken, {
    params: { page, page_size },
  });

export const postComment = (accessToken, { type, id, text }) =>
  authorizedPostRequest(`${COMMENTS}/${type}/${id}`)(accessToken, { text });

export const deleteComment = (accessToken, commentID) =>
  authorizedDeleteRequest(`${COMMENTS}/${commentID}`)(accessToken);

export default {
  getComments,
  postComment,
  deleteComment,
};
