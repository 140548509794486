<template>
  <div
    class="form-group px-0 my-0 d-flex align-self-center hera-form-checkbox"
    @click="$emit('click', $event)"
  >
    <label class="d-flex align-content-center">
      <input
        type="checkbox"
        :class="{ indeterminate }"
        :name="name"
        :value="val"
        :checked="isChecked"
        :title="label"
        :tabindex="tabindex"
        @change="onChange"
      />
      <span class="mx-2 hera-checkbox"></span>
      <span class="mr-auto hera-checkbox-title">
        {{ label }}
        <slot />
      </span>
    </label>
    <span
      v-show="error"
      class="form-text text-danger error-help"
      v-text="error"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'HeraCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    val: {
      type: null,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.value.includes(this.val);
    },
  },
  methods: {
    onChange() {
      if (this.isChecked) {
        this.value.splice(
          this.value.findIndex((el) => el === this.val),
          1
        );
      } else {
        this.value.push(this.val);
      }
      this.$emit('change', ...this.value);
    },
  },
};
</script>

<style lang="scss">
.hera-form-checkbox {
  input {
    position: absolute;
    z-index: -2;
    transform: scale(0);
    opacity: 0;

    &:focus {
      ~ .hera-checkbox::before {
        box-shadow: $btn-focus-box-shadow;
      }
    }
    &:checked {
      ~ .hera-checkbox::before {
        border-color: $primary;
        background-color: $primary;
        background-image: url('~@/assets/images/icons/checked.svg');
      }
    }
    &.indeterminate {
      ~ .hera-checkbox::before {
        border-color: $primary;
        background-color: $primary;
        background-image: url('~@/assets/images/checkbox-indeterminate.svg');
      }
    }
  }
  .hera-checkbox {
    color: white;
  }
  .hera-checkbox::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid $ph-secondary-text;
    background: center no-repeat;
    background-size: contain;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  label {
    margin: 0 -0.5rem;
  }
}
</style>
