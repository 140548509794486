// const state = {
//   designTheme: '',
//   userLocale: '',
//   locationTraking: false,
//   notifications: true
// }
export const INITIAL_USER_SETTINGS_STATE = () => {
  return {
    designTheme: '',
    userLocale: '',
    locationTraking: false,
    notifications: true,
  };
};

const getters = {};

const mutations = {
  changeNotificationSetting(state, payload) {
    state.notifications = payload;
  },
  changeLocationTrakingSetting(state, payload) {
    state.locationTraking = payload;
  },
  setUserLocale(state, payload) {
    state.userLocale = payload;
  },
  setUserDesignTheme(state, payload) {
    state.designTheme = payload;
  },
};

const actions = {};

export default {
  state: INITIAL_USER_SETTINGS_STATE(),
  getters,
  mutations,
  actions,
};
