<template>
  <transition
    name="fs-popup-anim"
    :duration="animated ? { enter: 2500, leave: 2000 } : 0"
  >
    <div
      class="d-flex flex-column align-items-center justify-content-center fs-popup"
      :class="{ error: errorPopup }"
    >
      <div class="text-center fs-popup__header">
        <span class="font-weight-normal hera-heading fs-popup__title">
          {{ title }}
        </span>
        <span class="font-size-base text-muted fs-popup__subtitle">
          {{ subTitle }}
        </span>
      </div>

      <div
        class="fs-popup__shapes d-flex align-items-center justify-content-center"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          style="width: 0; height: 0; position: absolute"
        >
          <linearGradient id="fs-popup__shapes-gradient">
            <stop class="fs-popup__shapes-gradient-from" />
            <stop offset="1" class="fs-popup__shapes-gradient-to" />
          </linearGradient>
        </svg>
        <svg
          class="fs-popup__shape-3"
          width="259"
          height="259"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M207.935 10.22C190.744 3.49633 159.853 0.268948 129.5 0C99.1466 0.268948 68.256 3.49633 51.3333 9.95109C15.339 23.9364 7.012 63.7408 7.012 63.7408C1.63973 86.3324 -0.240573 109.193 0.0280413 129.364C-0.240573 149.804 1.37111 172.665 7.012 195.257C7.012 195.257 15.339 235.061 51.3333 249.046C68.256 255.501 99.1466 258.728 129.5 259C159.853 258.728 190.744 255.77 207.664 249.046C243.661 235.061 251.985 195.257 251.985 195.257C257.358 172.665 259.24 149.804 258.972 129.633C259.24 109.193 257.629 86.3324 252.257 64.0097C252.257 64.0097 243.927 24.2054 207.935 10.22Z"
          />
        </svg>
        <svg
          class="fs-popup__shape-2"
          width="191"
          height="191"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M153.342 7.53679C140.664 2.57837 117.884 0.198336 95.5 0C73.1158 0.198336 50.3355 2.57837 37.8558 7.33845C11.3118 17.6519 5.17101 47.0057 5.17101 47.0057C1.20922 63.666 -0.177411 80.5246 0.0206791 95.3998C-0.177411 110.473 1.01113 127.332 5.17101 143.992C5.17101 143.992 11.3118 173.346 37.8558 183.66C50.3355 188.42 73.1158 190.8 95.5 191C117.884 190.8 140.664 188.618 153.142 183.66C179.688 173.346 185.827 143.992 185.827 143.992C189.789 127.332 191.177 110.473 190.979 95.5982C191.177 80.5246 189.989 63.666 186.027 47.2041C186.027 47.2041 179.884 17.8503 153.342 7.53679Z"
          />
        </svg>
        <svg
          class="fs-popup__shape-1"
          width="125"
          height="125"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M100.355 4.93245C92.0579 1.68742 77.1493 0.129801 62.5 0C47.8507 0.129801 32.9421 1.68742 24.7748 4.80265C7.40301 11.5523 3.38417 30.7629 3.38417 30.7629C0.791373 41.6662 -0.116106 52.6993 0.0135334 62.4344C-0.116106 72.2994 0.661733 83.3325 3.38417 94.2358C3.38417 94.2358 7.40301 113.446 24.7748 120.196C32.9421 123.311 47.8507 124.869 62.5 125C77.1493 124.869 92.0579 123.441 100.224 120.196C117.597 113.446 121.614 94.2358 121.614 94.2358C124.207 83.3325 125.116 72.2994 124.986 62.5643C125.116 52.6993 124.338 41.6662 121.745 30.8927C121.745 30.8927 117.725 11.6821 100.355 4.93245Z"
          />
        </svg>
        <div class="fs-popup__image">
          <slot name="image"></slot>
        </div>
      </div>

      <div class="fs-popup__footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FullscreenPopup',

  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    errorPopup: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    transferToBody: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.transferToBody) {
      document.body.appendChild(this.$el);
    }
  },
};
</script>

<style lang="scss">
.fs-popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, $modal-backdrop-opacity);
  z-index: 9999;

  &__title {
    display: block;
    line-height: 1.21em;
    margin-bottom: 0.32em;
  }

  &__subtitle {
    display: block;
    line-height: 1.375em;
    margin-bottom: 2.25em;
  }

  &__shapes {
    width: 259px;
    height: 259px;
    margin-bottom: 30px;

    .fs-popup__shapes-gradient-from {
      stop-color: $primary-gradient-from;
    }

    .fs-popup__shapes-gradient-to {
      stop-color: $primary-gradient-to;
    }

    .fs-popup__shape-1,
    .fs-popup__shape-2,
    .fs-popup__shape-3 {
      fill: url(#fs-popup__shapes-gradient);
    }

    .error & {
      .fs-popup__shape-1,
      .fs-popup__shape-2,
      .fs-popup__shape-3 {
        fill: $disabled-color;
      }
    }
  }

  &__shape-1,
  &__shape-2,
  &__shape-3 {
    position: absolute;
  }

  &__image {
    z-index: 1;
  }

  &.error {
    background: rgba(0, 0, 0, $modal-backdrop-opacity + 0.1);
  }
}

.fs-popup-anim {
  &-enter {
    opacity: 0;

    .fs-popup__image,
    .fs-popup__shape-1,
    .fs-popup__shape-2,
    .fs-popup__shape-3 {
      transform: scale(0);
    }

    .fs-popup__header,
    .fs-popup__footer {
      opacity: 0;
    }
  }

  &-enter-active {
    transition: opacity 0.5s;

    $image-duration: 0.4s;
    $image-timing-function: cubic-bezier(0.35, 0.87, 0.13, 1.66);
    $image-delay: 0.5s;

    .fs-popup__image {
      transition: transform $image-duration $image-timing-function $image-delay;
    }

    $shape-duration: 0.4s;
    $shape-timing-function: cubic-bezier(0.35, 0.87, 0.13, 1.66);
    $shape-1-delay: $image-delay + $image-duration + $shape-duration * 0 - 0.2s;
    $shape-2-delay: $image-delay + $image-duration + $shape-duration * 1 - 0.2s;
    $shape-3-delay: $image-delay + $image-duration + $shape-duration * 2 - 0.2s;

    .fs-popup__shape-1 {
      transition: transform $shape-duration $shape-timing-function
        $shape-1-delay;
    }

    .fs-popup__shape-2 {
      transition: transform $shape-duration $shape-timing-function
        $shape-2-delay;
    }

    .fs-popup__shape-3 {
      transition: transform $shape-duration $shape-timing-function
        $shape-3-delay;
    }

    $header-footer-duration: 0.9s;
    $header-footer-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    $header-footer-delay: $shape-3-delay + $shape-duration - 0.2s;

    .fs-popup__header,
    .fs-popup__footer {
      transition: opacity $header-footer-duration $header-footer-timing-function
        $header-footer-delay;
    }
  }

  &-leave-to {
    opacity: 0;

    .fs-popup__header,
    .fs-popup__footer {
      opacity: 0;
    }

    .fs-popup__shape-1,
    .fs-popup__shape-2,
    .fs-popup__shape-3 {
      transform: scale(2);
      opacity: 0;
    }

    .fs-popup__image {
      transform: scale(0);
    }
  }

  &-leave-active {
    transition: opacity 2s;

    $header-footer-duration: 0.3s;
    $header-footer-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    .fs-popup__header,
    .fs-popup__footer {
      transition: opacity $header-footer-duration $header-footer-timing-function;
    }

    $shape-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    $shape-duration: 0.3s;
    $shape-3-delay: $header-footer-duration + $shape-duration * 0 - 0.2s;
    $shape-2-delay: $header-footer-duration + $shape-duration * 1 - 0.2s;
    $shape-1-delay: $header-footer-duration + $shape-duration * 2 - 0.2s;

    .fs-popup__shape-1 {
      transition: all $shape-duration $shape-timing-function $shape-1-delay;
    }

    .fs-popup__shape-2 {
      transition: all $shape-duration $shape-timing-function $shape-2-delay;
    }

    .fs-popup__shape-3 {
      transition: all $shape-duration $shape-timing-function $shape-3-delay;
    }

    $image-duration: 0.5s;
    $image-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    $image-delay: $header-footer-duration + $shape-duration * 3 - 0.2s;

    .fs-popup__image {
      transition: transform $shape-duration $image-timing-function $image-delay;
    }
  }
}
</style>
