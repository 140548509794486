<template>
  <component
    :is="tag"
    class="hera-text-block"
    :class="[`hera-text-block--${variant}`]"
    v-bind="{ style }"
    ><slot>{{ text }}</slot></component
  >
</template>

<script>
export default {
  name: 'TextBlock',
  props: {
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      validator(val) {
        return (
          [
            'muted',
            'dark',
            'light',
            'primary',
            'secondary',
            'error',
            'success',
            'colored-primary',
            'bold',
          ].indexOf(val) !== -1
        );
      },
      default: 'primary',
    },
    size: {
      type: Number,
      default: 1,
    },
    lineHeight: {
      type: [Number, String],
      default: 1.57143,
    },
    tag: {
      type: String,
      default: 'span',
    },
    unsetStyles: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    style() {
      return {
        fontSize: this.unsetStyles ? null : `${this.size}rem`,
        lineHeight: this.unsetStyles ? null : this.lineHeight,
      };
    },
  },
};
</script>

<style lang="scss">
.hera-text-block {
  &--muted {
    color: $secondary-text-color;
    opacity: 0.6;
  }
  &--dark {
    color: $hera-hover;
  }
  &--primary {
    color: $primary-text-color;
  }
  &--colored-primary {
    color: $main-color;
  }
  &--secondary {
    color: $secondary-text-color;
  }
  &--error {
    color: $danger;
  }
  &--success {
    color: $success;
  }
  &--bold {
    font-weight: bold;
  }
  &--light {
    color: $ph-white;
  }
}
</style>
