<template>
  <header class="navbar d-flex align-items-center" :class="[layout]">
    <logo type="full" class="mt-1" />
    <slot />
  </header>
</template>

<script>
import logo from '@/components/common/HeraLogo';

export default {
  name: 'Navbar',
  components: {
    logo,
  },
  computed: {
    layout() {
      return this.$route.meta && this.$route.meta.layout
        ? this.$route.meta.layout + '-layout'
        : '';
    },
  },
};
</script>

<style lang="scss">
.navbar {
  background-color: $main-text-color;
  height: $header-height;
  padding-left: 4.875rem;
  padding-right: 4.875rem;
  justify-content: space-between;

  /* Place layout specific styles here
  &.admin-auth-layout {
  
  }
  &.admin-layout {
  
  }
  */
}
</style>
