import { authorizedPostRequest } from '@/api/requests';

const COMPLAINTS_BASE = 'complaints';

const postComplaint = (accessToken, payload) =>
  authorizedPostRequest(COMPLAINTS_BASE)(accessToken, payload);

export default {
  postComplaint,
};
