import defaultUser from './assets/default/user.svg';
import defaultClan from './assets/default/clan.svg';
import defaultOrganization from './assets/default/organization.png';
import defaultESPL from './assets/default/eSPL.png';
import defaultChannels from './assets/default/channel.svg';
import defaultGroup from './assets/default/group.svg';
import defaultGame from './assets/default/game.svg';
import defaultImage from './assets/default/custom.svg';

export default {
  defaultUser,
  defaultClan,
  defaultOrganization,
  defaultESPL,
  defaultChannels,
  defaultGroup,
  defaultGame,
  defaultImage,
};
