<template>
  <div class="d-flex flex-column h-100 admin-auth-layout">
    <Navbar />
    <div
      class="main-content d-flex flex-column flex-grow-1 align-items-center justify-content-center"
    >
      <form
        class="w-100 d-flex flex-column align-items-center mx-5"
        @submit.prevent="$emit('submit')"
      >
        <Title class="mb-2" line-height="1.2">{{ title }}</Title>
        <TextBlock class="mb-5 text-center" variant="secondary">{{
          subtitle
        }}</TextBlock>
        <div class="w-100 admin-auth-layout_content">
          <slot />
        </div>
        <SubmitBtn :label="btnLabel" :loading="isLoading" class="rounded" />
        <slot name="footer" />
      </form>
    </div>
    <TextBlock class="pb-3 text-center" :size="0.875" variant="muted" tag="div"
      >PLAYHERA CORE PORTAL</TextBlock
    >
  </div>
</template>

<script>
import { prop } from 'playhera-utils/factories';
import Navbar from '@/components/Navbar';
import TextBlock from '@/components/text/TextBlock';
import SubmitBtn from '@/components/form/HeraFormSubmitBtn';
import Title from '@/components/text/Title';

export default {
  name: 'AdminAuthLayout',
  components: {
    Navbar,
    TextBlock,
    SubmitBtn,
    Title,
  },
  props: {
    title: prop(String),
    subtitle: prop(String),
    btnLabel: prop(String),
    isLoading: prop(Boolean),
  },
};
</script>

<style lang="scss">
.admin-auth-layout {
  background-color: $page-bg-color;
  max-width: 1920px;
  min-height: 100vh;
  form {
    max-width: 25rem;
  }
  &_content {
    max-width: 18.75rem;
  }
}
</style>
