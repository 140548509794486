<template>
  <Panel
    :variant="variant"
    style="border-width: 2px"
    :class="{ 'block-loading': isLoading }"
  >
    <div
      class="row"
      style="min-height: 73px"
      :class="{ 'loader-mask': isLoading }"
    >
      <template v-if="hasError">
        <div class="col">
          <div class="row align-items-center h-100 no-gutters">
            <div class="col-3 text-center">
              <Icon name="charts" width="64" original />
            </div>
            <div class="col-9">
              <TextBlock class="mb-2" variant="error" :size="1"
                >Some error while loading data</TextBlock
              ><br /><TextBlock class="mb-2" variant="secondary" :size="1"
                >Please
                <a
                  class="btn-link p-0"
                  href="#"
                  @click.prevent.stop="$emit('refetch')"
                  >retry</a
                ></TextBlock
              >
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col d-flex flex-column justify-content-between">
          <TextBlock class="mb-2"
            ><strong>{{ label }}</strong></TextBlock
          >
          <TextBlock :size="1.625"
            ><strong>{{ total | localNumber('en-US') }}</strong></TextBlock
          >
        </div>
        <div class="col-5 d-flex flex-column justify-content-between">
          <div
            v-for="(option, index) in options"
            :key="option.key"
            class="row no-gutters justify-content-end"
            :class="{ 'mt-2': index > 0 }"
          >
            <div class="col">
              <TextBlock :size="0.875" variant="primary">{{
                option.key
              }}</TextBlock>
            </div>
            <div class="col text-right">
              <TextBlock :size="0.875" variant="primary">{{
                option.value | localNumber('en-US')
              }}</TextBlock>
            </div>
            <!-- <div class="col text-right">
              <TextBlock
                :size="0.875"
                :variant="
                  option.previousValue === 0
                    ? 'secondary'
                    : option.previousValue < 0
                    ? 'error'
                    : 'success'
                "
                >{{
                  option.previousValue | shortNumberDifference(1)
                }}</TextBlock
              >
            </div> -->
          </div>
        </div>
      </template>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/common/Panel';
import TextBlock from '@/components/text/TextBlock';
import Icon from '@/components/common/Icon';
export default {
  name: 'StatisticPanel',
  components: {
    Panel,
    TextBlock,
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    total: {
      type: [String, Number],
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'hover',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
