import { authorizedGetRequest } from '@/api/requests';

const POSTS = 'posts';
const POPULAR_PLAYERS = 'popularpeople';

export const getNewsFeeds = authorizedGetRequest(`${POSTS}/my/newsfeed`);
export const getPopularPlayers = authorizedGetRequest(POPULAR_PLAYERS);

export default {
  getNewsFeeds,
  getPopularPlayers,
};
