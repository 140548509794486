import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedDeleteRequest,
} from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const sendPrivateInvite = (tournamentID) =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/invites`);

export const getInvitedPlayers = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/invites`);

export const cancelPrivateInvite = (tournamentID) =>
  authorizedDeleteRequest(`${TOURNAMENTS}/${tournamentID}/invites`);

// export const acceptPrivateInvite = tournamentID =>
//   authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players/invites/accept`);
//
// export const rejectPrivateInvite = tournamentID =>
//   authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players/invites/reject`);

export default {
  sendPrivateInvite,
  getInvitedPlayers,
  cancelPrivateInvite,
  // acceptPrivateInvite,
  // rejectPrivateInvite,
};
