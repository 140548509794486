import { Validator } from 'vee-validate';

Validator.extend('email_or_phone', {
  getMessage(field) {
    return `The ${field} field should be email or phone.`;
  },
  validate(value) {
    // Simple validation. Phone should start with '+' and has length in range 6-16 (iOS logic).
    const r = /(^\+[0-9]{6,16}$)|(^.+@.+\..+$)/;
    return r.test(`${value}`);
  },
});
