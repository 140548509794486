/**
 * Permissions model for all account types and subject types (hera_admin, federation, media, etc)
 */
import {
  CLAN_TYPE,
  TOURNAMENT_TYPE,
  LEAGUE_TYPE,
  DASHBOARD_TYPE,
  PROFILE_TYPE,
  ORGANIZATION_TYPE,
  ADMINISTRATIVE_TYPE,
  ALL_PERMISSIONS,
} from '@/constants/permissions';

class PermissionsModel {
  constructor(data) {
    function checkPermission(permissions = {}, key = '') {
      return permissions[key] || permissions[ALL_PERMISSIONS];
    }

    this.fullPermissions = checkPermission(
      data[ADMINISTRATIVE_TYPE],
      ALL_PERMISSIONS
    );

    this.followClan = checkPermission(data[CLAN_TYPE], 'follow');
    this.inviteToClan = checkPermission(data[CLAN_TYPE], 'invite');
    this.joinClan = checkPermission(data[CLAN_TYPE], 'join');
    this.createClan = checkPermission(data[CLAN_TYPE], 'create');
    this.createTournament = checkPermission(data[TOURNAMENT_TYPE], 'create');
    this.joinTournament = checkPermission(data[TOURNAMENT_TYPE], 'join');
    this.editTournament = checkPermission(data[TOURNAMENT_TYPE], 'edit');
    this.viewCompetitions = checkPermission(data[TOURNAMENT_TYPE], 'view');
    this.createLeague = checkPermission(data[LEAGUE_TYPE], 'create');
    this.joinLeague = checkPermission(data[LEAGUE_TYPE], 'join');
    this.addToFriend = checkPermission(data[PROFILE_TYPE], 'addFriends');
    this.followProfile = checkPermission(data[PROFILE_TYPE], 'follow');
    this.followOrganization = checkPermission(
      data[ORGANIZATION_TYPE],
      'follow'
    );
    this.dashboard = checkPermission(data[DASHBOARD_TYPE], 'select');
  }
}

export default PermissionsModel;
