const LOCALE_STORAGE_KEY = 'locale';
const currentLocale = localStorage.getObjectFromItem(LOCALE_STORAGE_KEY) || {};

const state = {
  current: '',
  isLoading: false,
  countriesFetched: false,
  platformsFetched: false,
  gamesFetched: false,
  translationsFetched: false,
  currentLocale,
  isHeaderFixed: false,
  isVideoVisible: true,
  hasCover: false,
  isScreenLocked: false,
};
const getters = {
  isLoading: (state) => state.isLoading,
  countriesFetched: (state) => state.countriesFetched,
  translationsFetched: (state) => state.translationsFetched,
  platformsFetched: (state) => state.platformsFetched,
  dataReady: (state) => state.translationsFetched,
  currentLocale: (state) => state.currentLocale,
  localeDirection: (state) => state.currentLocale.direction || 'ltr',
  isRTL: (state) => state.currentLocale.direction === 'rtl',
  isHeaderFixed: (state) => state.isHeaderFixed,
  isVideoVisible: (state) => state.isVideoVisible,
  hasCover: (state) => state.hasCover,
  isScreenLocked: (state) => state.isScreenLocked,
};
const mutations = {
  setIsLoading(state) {
    state.isLoading = true;
  },
  unsetIsLoading(state) {
    state.isLoading = false;
  },
  changeCurrentStatus(state, payload) {
    state.current = payload;
  },
  countriesFetched(state) {
    state.countriesFetched = true;
  },
  platformsFetched(state) {
    state.platformsFetched = true;
  },
  gamesFetched(state) {
    state.gamesFetched = true;
  },
  translationsFetched(state) {
    state.translationsFetched = true;
  },
  setLocale(state, currentLocale = null) {
    state.currentLocale = currentLocale || {};
    localStorage.setObjectToItem(LOCALE_STORAGE_KEY, currentLocale);
  },

  fixHeader(state) {
    state.isHeaderFixed = true;
  },
  releaseHeader(state) {
    state.isHeaderFixed = false;
  },

  showVideo(state) {
    state.isVideoVisible = true;
  },
  hideVideo(state) {
    state.isVideoVisible = false;
  },

  hasCover(state) {
    state.hasCover = true;
  },
  hasNoCover(state) {
    state.hasCover = false;
  },
  enableLockScreen(state) {
    state.isScreenLocked = true;
  },
  disableLockScreen(state) {
    state.isScreenLocked = false;
  },
};
const actions = {
  lockScreen({ dispatch, commit }) {
    return dispatch('openModal', {
      name: 'LockScreen',
      settings: {
        clickOnBackdrop: false,
        escKey: false,
        mdlContentClass: 'shadow-none',
      },
    }).then(() => commit('enableLockScreen'));
  },
  unlockScreen({ dispatch, commit }) {
    dispatch('closeModal');
    commit('disableLockScreen');
  },
  toggleLockScreen({ dispatch, getters: { isScreenLocked } }, isLock) {
    const flag = isLock !== undefined ? isLock : !isScreenLocked;
    return flag ? dispatch('lockScreen') : dispatch('unlockScreen');
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
