<template>
  <button
    :id="id"
    type="button"
    class="text-truncate btn hera-btn"
    :class="[
      `hera-${variant}-btn`,
      { 'is-loading': loading, 'w-100': fluid, 'is-danger': isDanger },
    ]"
    :style="{ color: contentColor }"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot name="prepend"></slot>
    <icon
      v-if="icon"
      :key="icon"
      class="icon mx-1"
      :name="icon"
      :width="iconSize"
      fill="white"
    />
    <span v-if="label" class="mx-1">{{ label }}</span>
    <slot></slot>
    <slot name="append"></slot>
  </button>
</template>
<script>
import icon from '@/components/common/Icon';

const BUTTONVARIANTS = ['default', 'light', 'partially-transparent'];

export default {
  components: {
    icon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    contentColor: {
      type: String,
      default: 'white',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '18',
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator(val) {
        return !val || BUTTONVARIANTS.includes(val);
      },
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
button.hera-btn,
a.hera-btn {
  $height: 2.25rem; // 36px
  position: relative;
  height: $height;
  outline: none;
  border: 1px solid $border-stroke-color;
  border-radius: $height / 2;
  /*margin-left: auto;*/
  background: $page-bg-color;
  color: $ph-primary-text;
  padding-top: 0;
  padding-bottom: 0;
  //line-height: $height;
  font-size: $font-size-sm; // 0.875rem == 14px
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;

  &.h-sm,
  &.btn-sm {
    height: 1.75rem;
    line-height: 1;
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }

  &.is-loading {
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1rem;
      width: 1rem;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
      border: 2px solid;
      border-color: transparent transparent $grey-fill $grey-fill;
      border-radius: 50%;
      @include rotate-element;
    }
  }

  &.btn-primary {
    @include primary-background-gradient;
    &.is-loading::after {
      border-color: transparent transparent $white $white !important;
    }
  }
  &.btn-primary-outline {
    background: $page-bg-color;
    color: $ph-primary-text;
    border-color: $primary !important;
  }
  &.is-danger {
    border-color: $error-color;
  }
  &.btn-danger-outline {
    background: $page-bg-color;
    color: $ph-primary-text;
    border-color: $error-color !important;
  }
  &.hera-light-btn {
    color: $primary-color;
    background: transparent;
    border: 0;

    &:focus {
      box-shadow: none;
    }
    &:active {
      box-shadow: none !important;
    }
  }

  &.hera-partially-transparent-btn {
    background: rgba($page-bg-color, 0.2);
    border-color: transparent;
  }

  &:hover {
    color: lighten($ph-primary-text, 30%);
  }
}
</style>
