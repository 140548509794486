import { generateGetters, generateToggleMutations } from '@/utils/store';
import { merge, get } from 'lodash';
import { modalComponents } from '@/constants/modal';
/**
 * Default modal options factory function
 * @return {Object} empty modal options object
 */
const createDefaultModalOptions = () => ({
  component: null,
  props: {},
  events: {},
  settings: {
    clickOnBackdrop: true,
    escKey: true,
    variant: 'default',
  },
});
/**
 * Default initial state factory function
 * @return {Object} initial state object
 */
const INITIAL_MAIN_STATE = () => {
  return {
    activeModal: null,
    importedModals: {},
    modalIsShow: false,
  };
};

const getters = {
  /**
   * Generating simple getters for activeModal and modalIsShow state properties
   */
  ...generateGetters('activeModal', 'modalIsShow'),
  /**
   * Getter for click-on-backdrop setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {Boolean} click-on-backdrop prop value, true by default
   */
  clickOnBackdrop: (state) =>
    get(state, 'activeModal.settings.clickOnBackdrop', true),
  /**
   * Getter for esc-key setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {Boolean} esc-key prop value, true by default
   */
  escKey: (state) => get(state, 'activeModal.settings.escKey', true),
  /**
   * Getter for variant setting for ModalBackdrop component
   * @param {Object} state - state object
   * @return {String} - variant prop value, default by default
   */
  variant: (state) => get(state, 'activeModal.settings.variant') || 'default',
};

const mutations = {
  /**
   * Generating toggle nutation for modalIsShow state property
   */
  ...generateToggleMutations('modalIsShow'),
  /**
   * Setter for modal options in state
   * @param {Object} state - state object
   * @param {Object} payload - payload object
   * @property {string} payload.name - modal component name.
   * @property {Object} payload.props - modal component props.
   * @property {Object} payload.events - modal component events listeners.
   * @property {Object} payload.settings - ModalBackdrop settings.
   */
  updateOptions(state, { name, props, events, settings }) {
    if (!state.importedModals[name]) {
      state.importedModals[name] = createDefaultModalOptions();
    }

    merge(state.importedModals[name], {
      props,
      events,
      settings,
    });
  },
  /**
   * Setter for opening modal
   * Sets modal flag and active component from imported components
   * @param {Object} state - state object
   * @param {String} name - modal component name
   */
  showModal(state, name) {
    state.modalIsShow = true;
    state.activeModal = state.importedModals[name];
  },
};

const actions = {
  /**
   * Action for close modal
   * triggers toggleModalIsShow mutation
   * @param {Object} state - state context
   * @property {Function} state.commit - commit function
   */
  closeModal({ commit }) {
    commit('toggleModalIsShow', false);
  },
  /**
   * Action for opening modal
   * triggers showModal mutation
   * @param {Object} store - state context
   * @property {Function} store.commit - mutation trigger
   * @property {Object} store.state - current module state
   * @param {Object} payload - payload object
   * @property {string} payload.name - modal component name.
   * @property {Object} payload.props - modal component props.
   * @property {Object} payload.events - modal component events listeners.
   * @property {Object} payload.settings - ModalBackdrop settings.
   * @return {Promise} deferred triggering of showModal mutation
   * because of importing modal component
   * in case that component was already imported, promise resolves immediately
   */
  openModal({ commit, state }, { name, props, events, settings }) {
    commit('toggleModalIsShow', true);
    return new Promise((resolve, reject) => {
      if (!name) {
        return reject('Component Not Found');
      }

      commit('updateOptions', { name, props, events, settings });

      const currentModal = state.importedModals[name];
      if (!currentModal.component) {
        currentModal.component = modalComponents[name];

        return currentModal.component().then(() => {
          commit('showModal', name);
          return resolve();
        });
      } else {
        commit('showModal', name);
        return resolve();
      }
    });
  },
};
/**
 * Export of the vuex module
 */
export default {
  state: INITIAL_MAIN_STATE(),
  getters,
  mutations,
  actions,
};
