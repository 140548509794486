import { authorizedDeleteRequest, authorizedPostRequest } from '@/api/requests';

const PROFILE_BASE = 'profiles';
const FOLLOW_BASE = PROFILE_BASE + 'my/follow';

export const followUser = authorizedPostRequest(`${FOLLOW_BASE}/`);

export const friendRequest = (login) =>
  authorizedPostRequest(`${PROFILE_BASE}/${login}/friendship_requests`);

export const removeFromFriends = (login) =>
  authorizedDeleteRequest(`${PROFILE_BASE}/my/friends/${login}`);

export default {
  followUser,
  friendRequest,
  removeFromFriends,
};
