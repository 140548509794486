import { authorizedGetRequest, authorizedPutRequest } from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const getTournamentCustomFields = (tournamentID = null) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/registration_form`);

export const getPlayersRestrictions = (tournamentID = null) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players_restrictions`);

export const getPlayersColumnsSettings = (tournamentID) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/registration_form/visible`
  );

export const setPlayersColumnsSettings = (tournamentID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/registration_form/visible`
  );

export default {
  getTournamentCustomFields,
  getPlayersRestrictions,
  getPlayersColumnsSettings,
  setPlayersColumnsSettings,
};
