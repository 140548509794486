import {
  authorizedDeleteRequest,
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const getTournamentStages = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/stages`);

export const createTournamentStage = (tournamentID) =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/stages`);

export const getTournamentStage = (tournamentID, stageID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/stages/${stageID}`);

export const updateTournamentStage = (tournamentID, stageID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/stages/${stageID}`);

export const deleteTournamentStage = (tournamentID, stageID) =>
  authorizedDeleteRequest(`${TOURNAMENTS}/${tournamentID}/stages/${stageID}`);

export const getTournamentStagePlayers = (tournamentID, stageID) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/players`
  );

export const getStagePlayersAllAppliciants = (tournamentID, stageID) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/stage/${stageID}/all_players`
  );

export const setStagePlayerParamsAllAppliciants = (tournamentID, stageID) =>
  authorizedPostRequest(
    `${TOURNAMENTS}/${tournamentID}/stage/${stageID}/players`
  );

export const setStagePlayersExtendedData = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/players`
  );

export const setStagePlayersCompleteQalification = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/qualify`
  );

export const drawStage = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/draw_blocks`
  );

export const setFillBlockParticipants = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/fill_blocks`
  );

export const publishStage = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/publish`
  );

export const unPublishStage = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/reset`
  );

export const startStage = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/start`
  );

export const getStagePlayersColumnsSettings = (tournamentID, stageID) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/registration_form/visible`
  );

export const setStagePlayersColumnsSettings = (tournamentID, stageID) =>
  authorizedPutRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/registration_form/visible`
  );

export const getStagePlayersColumnsSettingsAllApplicants = (
  tournamentID,
  stageID
) =>
  authorizedGetRequest(
    `${TOURNAMENTS}/${tournamentID}/stages/${stageID}/registration_form/visible/all_applicants`
  );

export default {
  getTournamentStages,
  getTournamentStage,
  createTournamentStage,
  updateTournamentStage,
  deleteTournamentStage,
  getTournamentStagePlayers,
  getStagePlayersAllAppliciants,
  setStagePlayerParamsAllAppliciants,
  getStagePlayersColumnsSettingsAllApplicants,
  setStagePlayersExtendedData,
  drawStage,
  setFillBlockParticipants,
  publishStage,
  unPublishStage,
  startStage,
  getStagePlayersColumnsSettings,
  setStagePlayersColumnsSettings,
};
