import { prop } from 'playhera-utils';

export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: prop(null, null),
  },
  data() {
    return {
      localValue: null,
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.value || this.localValue;
      },
      set(newValue) {
        this.$emit('input', newValue);
        this.localValue = newValue;
      },
    },
  },
};
