import { authorizedGetRequest, simpleGetRequest } from '@/api/requests';

const SETTINGS_BASE = 'settings/';
const CALLING_CODES = SETTINGS_BASE + 'calling_codes';
const SUGGEST_COUNTRIES = SETTINGS_BASE + 'suggest/countries';
const SUGGEST_CITY = SETTINGS_BASE + 'suggest/city';
const PLATFORMS = SETTINGS_BASE + 'platforms';
const GENDERS = SETTINGS_BASE + 'genders';
const GAMES = SETTINGS_BASE + 'games';

const getCallingCodes = simpleGetRequest(CALLING_CODES, {
  preventCancelRequest: true,
});

const suggestCountries = (query) =>
  simpleGetRequest(`${SUGGEST_COUNTRIES}?Prefix=${query}`);

const suggestCities = (country, query) =>
  simpleGetRequest(`${SUGGEST_CITY}?Country=${country}&Prefix=${query}`);

const getPlatforms = authorizedGetRequest(PLATFORMS, {
  preventCancelRequest: true,
});

const getGames = (published = false) =>
  authorizedGetRequest(GAMES + `?published_only=${published.toString()}`, {
    preventCancelRequest: true,
  });

const getGenders = simpleGetRequest(GENDERS, {
  preventCancelRequest: true,
});

export default {
  getCallingCodes,
  suggestCountries,
  suggestCities,
  getPlatforms,
  getGames,
  getGenders,
};
