import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import { TOURNAMENTS } from './tournaments';

export const createTournamentConversation = (tournamentID) =>
  authorizedPostRequest(`${TOURNAMENTS}/${tournamentID}/conversations`);

export const getTournamentConversationAvailablePlayers = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/players/conversation`);

export const addAllTournamentConversationAvailablePlayers = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/players/conversation`);

export default {
  createTournamentConversation,
  getTournamentConversationAvailablePlayers,
  addAllTournamentConversationAvailablePlayers,
};
