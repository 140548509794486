import store from '@/store';
import { ifAuthorized } from '@/router/guards';

import Dashboard from './views/dashboard';

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: ifAuthorized(store),
    meta: {
      layout: 'admin',
    },
  },
];
