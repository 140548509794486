import {
  authorizedGetRequest,
  authorizedPostRequest,
  authorizedPutRequest,
} from '@/api/requests';

import blocksEndpoints from './tournamentsBlocks';
import stagesEndpoints from './tournamentsStages';
import playersEndpoints from './tournamentsPlayers';
import registrationFormEndpoints from './tournamentsRegistrationForm';
import conversationsEndpoints from './tournamentsConversations';
import invitesEndpoints from './tournamentsInvitePlayers';

export const TOURNAMENTS = 'tournaments';

export const createTournament = authorizedPostRequest(`${TOURNAMENTS}`);

export const getTournament = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}`);

export const getTournamentDraft = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/draft`);

export const updateTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}`);

export const announceTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/announce`);

export const drawTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/draw`);

export const completeTournamentQualification = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/qualify`);

export const publishTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/publish`);

export const rejectTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/reject`);

export const restartTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/restart`);

export const archiveTournament = (tournamentID) =>
  authorizedPutRequest(`${TOURNAMENTS}/${tournamentID}/archive`);

export const getTournamentResults = (tournamentID) =>
  authorizedGetRequest(`${TOURNAMENTS}/${tournamentID}/results`);

export const getTournaments = authorizedGetRequest(`${TOURNAMENTS}`, {
  ignoreParams: true,
});

export const getAdminTournaments = authorizedGetRequest(
  `${TOURNAMENTS}/draft/my`,
  {
    ignoreParams: true,
  }
);

export default {
  createTournament,
  getTournament,
  getTournamentResults,
  getTournamentDraft,
  updateTournament,
  announceTournament,
  publishTournament,
  rejectTournament,
  restartTournament,
  archiveTournament,
  drawTournament,
  completeTournamentQualification,
  getTournaments,
  getAdminTournaments,
  //conversations
  ...conversationsEndpoints,
  //registration form
  ...registrationFormEndpoints,
  //players
  ...playersEndpoints,
  //invites,
  ...invitesEndpoints,
  //stages
  ...stagesEndpoints,
  //blocks
  ...blocksEndpoints,
};
