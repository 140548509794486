<template>
  <FormBtn
    type="submit"
    class="btn-primary hera-btn-submit"
    v-bind="{ loading, disabled, id, label }"
  />
</template>

<script>
import FormBtn from '@/components/form/HeraFormBtn';

export default {
  components: { FormBtn },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
button.hera-btn-submit {
  border: none !important;
  min-width: 14em;
  @include primary-background-gradient(135deg);
  border-radius: 1.5rem;
}
</style>
