// const TOKEN_VALIDITY_THRESOLD = 3570000 // token expires in 30 sec
const TOKEN_VALIDITY_THRESOLD = 30000;

export const isTokenValid = (token) => {
  let now = Date.now();
  return token.expires - now > TOKEN_VALIDITY_THRESOLD;
};

export const scrollToBottom = (el) => {
  if (!el) {
    return;
  }
  el.scrollTop = el.scrollHeight;
};

export const noOp = () => {};

/**
 * replace long-spaces i string. Ex: `     foo          bar       ` -> `foo bar`
 * @param str {string} - input string
 * @returns {string} - result
 */
export const textCollapsed = (str) => {
  return str
    .trim()
    .split(/[\s]{2,}/)
    .join(' ');
};

/**
 * Check is vue-router routes are equal. Hash ignore.
 * Ex '/foo/' != '/bar/'
 * Ex '/foo/#hash' == '/foo/'
 * @param {Object} from - Vue route
 * @param {Object} to  - Vue route
 * @returns {Boolean} - result
 */
export const areRoutesEqual = (from, to) => {
  return to.fullPath.split('#')[0] === from.fullPath.split('#')[0];
};

export const getFileExt = (fileName) => {
  return fileName ? fileName.replace(/^.+\.(.*?)$/, '$1') : '';
};

export const getFileNameWithoutExt = (fileName) => {
  return fileName ? fileName.replace(/\.(.*?)$/, '') : '';
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
