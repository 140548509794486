export const CLAN_TYPE = 'clan';
export const TOURNAMENT_TYPE = 'tournament';
export const LEAGUE_TYPE = 'league';
export const DASHBOARD_TYPE = 'dashboard';
export const PROFILE_TYPE = 'profile';
export const ORGANIZATION_TYPE = 'organization';
export const ADMINISTRATIVE_TYPE = 'administrative';
export const ALL_PERMISSIONS = 'all';
export const BASE_PERMISSIONS = {
  profile: {
    addToFriend: true,
    createClan: true,
    followClan: true,
    followOrganization: true,
    followProfile: true,
    inviteToClan: true,
    joinClan: true,
    joinLeague: true,
    joinTournament: true,
    viewCompetitions: true,
  },
  organization: {
    followClan: true,
    followOrganization: true,
    followProfile: true,
  },
};
export default {
  CLAN_TYPE,
  TOURNAMENT_TYPE,
  LEAGUE_TYPE,
  DASHBOARD_TYPE,
  PROFILE_TYPE,
  ORGANIZATION_TYPE,
  ADMINISTRATIVE_TYPE,
  ALL_PERMISSIONS,
  BASE_PERMISSIONS,
};
