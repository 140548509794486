import { lowerCase, isNumber } from 'lodash';
import { localDate, secToDuration } from '@/filters';

// TODO: think about such implementation of detecting timestamp fields
// for @olsy and @olbe

const DATE_TIME_TYPES = ['DateEnd', 'DateStart'];
const DURATION_FIELDS = ['MatchDuration'];

export const errorMessagePropsFactory = ({ error, context }, type) => {
  context = context || [];
  error = error || '';

  const translateKey = `_web_server_errors-${error}`,
    translateDefault = Object.entries(context)
      .reduce(
        (acc, [key, value]) => {
          if (key !== 'message' || value) {
            acc.push(`${key}: {${key}}`);
          }
          return acc;
        },
        [`{field}: ${lowerCase(error)}`]
      )
      .join(', '),
    props = Object.entries(context).reduce((acc, [key, value]) => {
      const contextValue = parseInt(`${value}`);
      // TODO: think about such implementation of detecting timestamp fields
      // for @olsy and @olbe
      const isDateField = DATE_TIME_TYPES.includes(type) && value;

      // temporary method to detect timestamp in value from server
      // timestamp = integer with exactly 10 digits
      //
      const isTimestamp =
        isNumber(contextValue) && contextValue.toString().length === 10;
      acc[key] = DURATION_FIELDS.includes(type)
        ? secToDuration(+value)
        : isTimestamp || isDateField
        ? localDate(+value)
        : `${value}`;
      return acc;
    }, {});

  return {
    translateKey,
    translateDefault,
    props,
  };
};
