import { USER_LOCALE } from '@/api/user-locale';
//import Vue from 'vue'
/*
import router from './router'

const months = [
  router.app.t('_web_profile_january', 'January'),
  router.app.t('_web_profile_february', 'February'),
  router.app.t('_web_profile_march', 'March'),
  router.app.t('_web_profile_april', 'April'),
  router.app.t('_web_profile_may', 'May'),
  router.app.t('_web_profile_june', 'June'),
  router.app.t('_web_profile_july', 'July'),
  router.app.t('_web_profile_august', 'August'),
  router.app.t('_web_profile_september', 'September'),
  router.app.t('_web_profile_october', 'October'),
  router.app.t('_web_profile_november', 'November'),
  router.app.t('_web_profile_december', 'December')
];

console.log('months', months)
*/

import { DateTime, Duration } from 'luxon';
import { isDate, isFinite, startCase, isString, floor } from 'lodash';

// export const localDate = (value, options = []) => {
//   value = +value;
//   if (!value) return '';
//   if (value < 1e10) value *= 1000;
//   return new Date(value).toLocaleString(...options);
// };

export const shortNumber = (value, precision = 0) => {
  return value === 0
    ? '?'
    : Math.abs(value) > 1000
    ? `${floor(value / 1000, precision)}K`
    : `${value}`;
};

export const shortNumberDifference = (value, precision = 0) => {
  const result =
    Math.abs(value) > 1000 ? `${floor(value / 1000, precision)}K` : `${value}`;
  return value === 0 ? '-' : value > 0 ? `+${result}` : `${result}`;
};

export const localDate = (value, options = null) => {
  let initialDatetime;
  if (isDate(value)) {
    initialDatetime = DateTime.fromJSDate(value, { zone: 'UTC' });
  } else if (value && isFinite(parseInt(value))) {
    initialDatetime = DateTime.fromSeconds(value, { zone: 'UTC' });
  } else {
    initialDatetime = DateTime.invalid('Wrong Date value provided');
  }

  return initialDatetime.isValid
    ? initialDatetime
        .toLocal()
        .toLocaleString(options || DateTime.DATETIME_SHORT)
    : '';
};

export const formatDate = (value, format = DateTime.DATETIME_SHORT) => {
  let initialDatetime;
  if (isDate(value)) {
    initialDatetime = DateTime.fromJSDate(value, { zone: 'UTC' });
  } else if (value && isFinite(parseInt(value))) {
    initialDatetime = DateTime.fromSeconds(value, { zone: 'UTC' });
  } else {
    initialDatetime = DateTime.invalid('Wrong Date value provided');
  }

  return initialDatetime.isValid
    ? initialDatetime.toLocal().toFormat(format)
    : '';
};

export const separatedWithComma = (value) => {
  let result = [];
  if (isString(value)) {
    result.push(value);
  } else if (Array.isArray(value)) {
    result = value;
  } else {
    throw new Error('Invalid parameter type: should be an array or string');
  }
  return result.join(', ');
};

export const secToDuration = (value, format = '') => {
  let initialDuration;
  if (value && isFinite(parseInt(value))) {
    initialDuration = Duration.fromObject({ seconds: value }).normalize();
  } else {
    initialDuration = Duration.invalid('Wrong Duration value provided');
  }

  return initialDuration.isValid
    ? initialDuration.toFormat(format || `hh'h' mm'm'`)
    : '';
};

export default {
  localDate,
  secToDuration,
  separatedWithComma,
  timeAgo: function (value, $t) {
    const monthNames = [
      $t('_web_profile_january', 'January'),
      $t('_web_profile_february', 'February'),
      $t('_web_profile_march', 'March'),
      $t('_web_profile_april', 'April'),
      $t('_web_profile_may', 'May'),
      $t('_web_profile_june', 'June'),
      $t('_web_profile_july', 'July'),
      $t('_web_profile_august', 'August'),
      $t('_web_profile_september', 'September'),
      $t('_web_profile_october', 'October'),
      $t('_web_profile_november', 'November'),
      $t('_web_profile_december', 'December'),
    ];

    const interval = new Date().getTime() / 1000 - value;
    const mins = Math.floor(interval / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 60);

    if (mins < 1) {
      return $t('_web_profile_recently', 'recently');
    }

    if (hours < 1) {
      return `${mins} ${$t('_web_profile_minuts_ago', 'minuts ago')}`;
    }

    if (days < 1) {
      return `${hours} ${$t('_web_profile_hours_ago', 'hours ago')}`;
    }

    const d = new Date(value * 1000);
    return `${d.getDate()} ${
      monthNames[d.getMonth()]
    } ${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}`;
  },

  formatTimeFromSeconds(val) {
    return new Date(val * 1e3).toLocaleTimeString('it-IT', { timeZone: 'UTC' });
  },

  multiline(val) {
    return val.replace(/(?:\r\n*)|\n/gm, '<br>');
  },

  /**
   * parse unix-time (in seconds) to ISO time (d/m/y)
   * @param input
   * @returns {string}
   */
  parseDate(input) {
    const date = new Date(input * 1e3);

    return date.toLocaleDateString('en-US');
  },

  /**
   * parse unix-time (in seconds) to ISO time (hh:MM (A|P)M)
   * @param input
   * @returns {string}
   */
  parseTime(input) {
    const date = new Date(input * 1e3);

    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  },

  /**
   * parse unix-time (in seconds) to ISO time (hh:MM (A|P)M) if its today or to ISO date (d/m/y)
   * @param input
   * @returns {string}
   */
  parseDateTime(input) {
    const date = new Date(input * 1e3);
    const today = new Date();
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    } else {
      return date.toLocaleDateString('en-US');
    }
  },

  /**
   * turncate text ({ "Loooooooong text" | truncate(8)} -> "Loooo...")
   * @param {string} input
   * @param {number} length
   * @returns {string}
   */
  truncate(input, length) {
    if (typeof input !== 'string') return '';
    return input.length > length + 3
      ? input.slice(0, length - 3) + '...'
      : input;
  },

  /**
   * startCase for text ({ "some_text" | startCase} -> "Some Text")
   * @param {string} input
   * @returns {string}
   */
  startCase,
  shortNumber,
  shortNumberDifference,
  formatDate,
  localNumber(input, locale = USER_LOCALE) {
    return new Intl.NumberFormat(locale).format(input);
  },
};
