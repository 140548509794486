import { camelCase, upperFirst } from 'lodash';
//   Possible layouts names -> file name in lowercase-kebab-case
//   'main-layout',
//   'admin-layout',
//   'admin-auth-layout',
//   'auth-layout',
//   'profile-setup-layout',
//   'market-layout',
//   'empty-layout',
//
export default (layoutsNames) => {
  const layouts = Array.isArray(layoutsNames) ? layoutsNames : [layoutsNames];
  return layouts.map((name) => {
    const path = `${upperFirst(camelCase(name))}`;
    return {
      name,
      component: function (resolve) {
        require([`./${path}`], resolve);
      },
    };
  });
};
