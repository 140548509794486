'use strict';

import axios from 'axios';
import qs from 'qs';

// import router from '@/router';

export const CONFIG = require(`${process.env.VUE_APP_HERA_ENV}`);
export const BASE_URL = CONFIG.baseURL;
export const TERMS_LINK_JAPAN = CONFIG.termsLinkJapan;
export const CONDITIONS_LINK_JAPAN = CONFIG.termsLinkJapan; // now conditions and terms are combined
export const PRIVACY_LINK_JAPAN = CONFIG.privacyLinkJapan;
export const TERMS_LINK_MENA = CONFIG.termsLinkMena;
export const CONDITIONS_LINK_MENA = CONFIG.termsLinkMena; // now conditions and terms are combined
export const PRIVACY_LINK_MENA = CONFIG.privacyLinkMena;
export const EMAIL_SUPPORT = CONFIG.emailSupport;

const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });

export const API = axios.create({
  baseURL: BASE_URL,
  paramsSerializer,
});

// Temporary interceptor
// let redirected = false;
// API.interceptors.response.use(
//   res => res,
//   err => {
//     if (err.response.status === 403 && !redirected) {
//       redirected = true;
//       // TODO - TH-7074 remove it after implementing 401 error in backend when token outdate
//       // sometime router.currentRoute can not get current route (vue-router problem)
//       /* eslint-disable */
//       const urlWithoutBase = window.location.href.replace(
//         /^(?:\/\/|[^\/]+)*\//,
//         ''
//       );
//       /* eslint-enable */
//       router.replace(urlWithoutBase);
//     }
//     throw err;
//   }
// );

export default {
  CONFIG,
  BASE_URL,
  TERMS_LINK_JAPAN,
  CONDITIONS_LINK_JAPAN,
  PRIVACY_LINK_JAPAN,
  TERMS_LINK_MENA,
  CONDITIONS_LINK_MENA,
  PRIVACY_LINK_MENA,
  EMAIL_SUPPORT,
  API,
};
