<!-- TODO - TH-7091 with popover-->
<template>
  <div class="info-tooltip">
    <div class="info-tooltip_activator">
      <AppIcon
        v-if="showIcon"
        class="info-tooltip_activator__default"
        name="info"
        width="16"
      />
      <slot />
    </div>

    <div class="info-tooltip_content">
      <h5 v-if="title" class="info-tooltip_content_title">{{ title }}</h5>
      <span class="info-tooltip_content_text">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/common/Icon.vue';

export default {
  name: 'InfoTooltip',
  components: { AppIcon },

  props: {
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.info-tooltip {
  position: relative;
  display: inline-block;

  &_activator {
    display: flex;
    align-items: baseline;
    cursor: help;

    &__default {
      color: $disabled-color;
    }
  }

  &_content {
    font-size: $font-size-xs;
    position: absolute;
    top: calc(100% + 10px);
    z-index: 9;
    width: max-content;
    max-width: 200px;
    padding: 15px;
    color: $text-primary-color;
    background: $element-bg-color;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3);
    word-wrap: break-word;
    opacity: 0;
    visibility: hidden;
  }

  &:hover &_content {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
  }
}
</style>
