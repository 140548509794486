import { authorizedGetRequest } from '@/api/requests';

const PLAYERS_BASE = 'profiles';

export const getPlayers = authorizedGetRequest(`${PLAYERS_BASE}/`, {
  ignoreParams: true,
});

export default {
  getPlayers,
};
