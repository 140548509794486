import { API } from '@/api/config';
import { authorizedPostRequest, deleteAuthTokenInHeader } from '@/api/requests';

const FILES_BASE = 'files/';
const PREUPLOAD = FILES_BASE + 'preupload';
const ADMIN_PREUPLOAD = FILES_BASE + 'admin_preupload';

const filePreupload = authorizedPostRequest(PREUPLOAD);

const filePreuploadForAdmin = authorizedPostRequest(ADMIN_PREUPLOAD);

const fileUpload = (url) => (requestPayload) => {
  deleteAuthTokenInHeader();
  return API.put(url, requestPayload.blob, {
    headers: {
      'Content-Type': 'application/octet-stream',
      // 'Content-Length': requestPayload.size,
      'x-ms-blob-type': 'BlockBlob',
    },
  });
};

export default {
  filePreuploadForAdmin,
  filePreupload,
  fileUpload,
};
