<template>
  <Layout
    v-bind="{ title, subtitle, btnLabel, isLoading }"
    @submit="formSubmit"
  >
    <template v-if="!isCodeSent">
      <TextInput
        v-model.trim="login"
        v-validate="'required|email_or_phone'"
        name="login"
        label="Email or Phone"
        :error="errors.first('login')"
        class="mb-2 w-100"
      />
    </template>

    <template v-else-if="!isCodeVerifyed">
      <TextInput
        key="code"
        v-model.trim="code"
        v-validate="'required|numeric|length:4'"
        name="code"
        label="Enter code"
        :error="errors.first('code')"
        class="mb-2 w-100"
      />
      <TextBlock slot="footer" class="mt-5 text-center" variant="secondary">
        <template v-if="loginType === 'email'">
          If you didn’t get e-mail, try to
          <CountdownLink ref="countdown" v-slot="{ time }" @click="resendCode">
            <template v-if="time">re-send in {{ time }} sec</template>
            <template v-else>re-send</template>
          </CountdownLink>
          email or check spam folder
        </template>
        <template v-else>
          If you didn’t get SMS, try to
          <CountdownLink ref="countdown" v-slot="{ time }" @click="resendCode">
            <template v-if="time">re-send in {{ time }} sec</template>
            <template v-else>re-send</template>
          </CountdownLink>
        </template>
      </TextBlock>
    </template>

    <template v-else-if="isCodeVerifyed">
      <TextInput
        key="newPassword"
        v-model.trim="newPassword"
        v-validate="'required'"
        name="password"
        label="New password"
        type="password"
        :error="errors.first('password')"
        class="mb-2 w-100"
      />
    </template>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TextInputWithLabelAndError as TextInput } from 'playhera-components';
import isLoadingMixin from '@/mixins/isLoading';
import Layout from '../../layouts/AuthLayout';
import TextBlock from '@/components/text/TextBlock';
import CountdownLink from './components/CountdownLink';

export default {
  name: 'ResetPassword',
  components: {
    Layout,
    TextInput,
    TextBlock,
    CountdownLink,
  },
  mixins: [isLoadingMixin],
  inject: ['$validator'],
  data() {
    return {
      login: '',
      password: '',
      isCodeSent: false,
      code: '',
      isCodeVerifyed: false,
      token: null,
      tokenExpires: null,
      newPassword: '',
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    loginType() {
      return this.login.includes('@') ? 'email' : 'phone';
    },
    title() {
      return !this.isCodeSent
        ? 'Restore password'
        : !this.isCodeVerifyed
        ? `Verify your ${this.loginType}`
        : 'Create new password';
    },
    subtitle() {
      return !this.isCodeSent
        ? 'Please, provide your email or phone, we`ll send confirmation code there'
        : !this.isCodeVerifyed
        ? `Please, enter confirmation code here. We have sent it to your ${this.loginType} ${this.login}`
        : 'Please, provide new password';
    },
    btnLabel() {
      return !this.isCodeSent
        ? 'Send'
        : !this.isCodeVerifyed
        ? 'Verify'
        : 'Create password';
    },
  },
  methods: {
    ...mapActions([
      'requestPass',
      'verifyUser',
      'submitPass',
      'clearAllTokens',
      'successNotify',
      'errorNotify',
    ]),

    sendCode() {
      return this.requestPass({
        identity: this.login,
        type: this.loginType,
      }).then(() => (this.isCodeSent = true));
    },

    resendCode() {
      this.sendCode()
        .then(() => this.successNotify('Code sent!'))
        .catch(this.errorNotify);
      this.$refs.countdown.start();
    },

    verifyCode() {
      return this.verifyUser({
        identity: this.login,
        type: this.loginType,
        code: this.code,
        setTokens: false,
      }).then((data) => {
        this.isCodeVerifyed = true;
        this.token = data.access_token;
        this.tokenExpires = data.access_token_expires * 1000;
      });
    },

    createPassword() {
      if (Date.now() > this.tokenExpires) {
        this.isCodeSent = this.isCodeVerifyed = false;
        this.errorNotify('Your authorization is expired. Please try again.');
        return;
      }
      return this.submitPass({ password: this.newPassword, token: this.token })
        .then(() => this.successNotify('Password has been changed!'))
        .then(() => this.$router.push('/'));
    },

    formSubmit() {
      if (this.errors.count()) {
        return;
      }
      this.setLoading();
      this.$validator
        .validateAll()
        .then(
          (result) =>
            result ? Promise.resolve() : Promise.reject({ silent: true }) // eslint-disable-line
        )
        .then(() => {
          if (!this.isCodeSent) {
            return this.sendCode();
          } else if (!this.isCodeVerifyed) {
            return this.verifyCode();
          } else if (this.isCodeVerifyed) {
            return this.createPassword();
          }
        })
        .catch((error) => !error.isValidationError && this.errorNotify(error))
        .finally(this.unsetLoading);
    },
  },
};
</script>
