const focus = {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    if (el.hasAttribute('autofocus')) {
      el.focus();
    }
  },
};

export default {
  focus,
};
