import api from '@/api';
import { isTokenValid } from '@/utils';
import PermissionsModel from '@/models/Permissions';
import { BASE_PERMISSIONS } from '@/constants/permissions';
import { mapValues } from 'lodash';
const state = {
  status: '',
  tempToken: JSON.parse(localStorage.getItem('PHCPtempToken')) || {},
  accessToken: JSON.parse(localStorage.getItem('PHCPaccessToken')) || {},
  refreshToken: JSON.parse(localStorage.getItem('PHCPrefreshToken')) || {},
  isAdmin: false, //always false for WEB_USER portal
  isRefreshProcessing: false,
  refreshStopover: Promise.resolve(),
  isOrganization: !!localStorage.getItem('isOrganization'),
  permissions: JSON.parse(localStorage.getItem('permissions')) || {},
};

const getters = {
  tempToken: (state) => state.tempToken.token,
  accessToken: (state) => state.accessToken.token,
  refreshToken: (state) => state.refreshToken.token,
  permissions: (state) => state.permissions,
  hasToken: (state) => (tokenType) => Boolean(state[tokenType].token),
  isRefreshProcessing: (state) => state.isRefreshProcessing,
  tokenObject: (state) => (tokenType) => state[tokenType],
  isAuthorized: (state, getters) => {
    return getters.hasToken('accessToken') && getters.hasToken('refreshToken');
  },
  isAuthenticated: (state, getters) => {
    return isTokenValid(getters.tokenObject('tempToken'));
  },
  isPartiallyAuthorized(state, getters) {
    return isTokenValid(getters.tokenObject('accessToken')) && !state.isAdmin;
  },
  isTempTokenOnly(state, getters) {
    return getters.tempToken && !getters.isAuthorized;
  },
  areAllTokensInvalid(state, getters) {
    return !getters.isAuthenticated && !getters.isAuthorized;
  },
  refreshStopover: (state) => state.refreshStopover,
  authStatus: (state) => state.status,
  isAdmin: (state) => state.isAdmin,
  isOrganizationToken: (state) => state.isOrganization,
};

const mutations = {
  /* DONT use here (Attention for merge with WEB_ADMIN portal files!)

  setIsAdmin(state, payload){
    state.isAdmin = payload
    payload
      ? localStorage.setItem('isAdmin', payload)
      : localStorage.removeItem('isAdmin')
  },
  */
  setToken(state, payload) {
    let token = {
      token: payload.token,
      expires: payload.expires,
    };
    localStorage.setItem(`PHCP${payload.type}`, JSON.stringify(token));
    state[payload.type] = token;
  },
  setTokenInStore(state, { tokenKey, token }) {
    state[tokenKey] = token;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  codeVerify(state) {
    state.status = 'verifying code';
  },
  codeRequest(state, payload) {
    // receive token expires in seconds
    // need to convert to miliseconds
    const token = {
      token: payload.temporary_token,
      expires: payload.expires * 1000,
    };
    localStorage.setItem('PHCPtempToken', JSON.stringify(token));
    state.tempToken = { ...state.tempToken, ...token };
    state.status = 'code requested';
  },
  authRequest(state) {
    state.status = 'checking if user exist';
  },
  userCreateRequest(state) {
    state.status = 'creating new user';
  },
  authSuccess(state, payload) {
    //set flag to local storage
    if (payload.data.context.type === 'organization') {
      state.isOrganization = true;
      localStorage.setItem('isOrganization', true);
    } else {
      localStorage.removeItem('isOrganization');
    }
    // receive token expires in seconds
    // need to convert to miliseconds
    const accessToken = {
      token: payload.data.access_token,
      expires: payload.data.access_token_expires * 1000,
    };
    localStorage.setItem('PHCPaccessToken', JSON.stringify(accessToken));
    state.accessToken = { ...state.accessToken, ...accessToken };

    if (payload.data.refresh_token) {
      state.tempToken = {};
      localStorage.removeItem('PHCPtempToken');
      // receive token expires in seconds
      // need to convert to miliseconds
      const refreshToken = {
        token: payload.data.refresh_token,
        expires: payload.data.refresh_token_expires * 1000,
      };
      localStorage.setItem('PHCPrefreshToken', JSON.stringify(refreshToken));
      state.refreshToken = { ...state.refreshToken, ...refreshToken };
    }
    state.status = 'autorized';
  },
  authError(state, error) {
    state.status = error;
    // localStorage.removeItem('temp-token')
    // localStorage.removeItem('access-token')
    // localStorage.removeItem('refresh-token')
    // console.log(error)
  },
  requestPass(state) {
    state.status = 'user forgot his password';
  },
  submitPass(state) {
    state.status = 'user create new password';
  },
  refreshingTokens(state) {
    state.status = 'Refreshing Tokens';
  },
  passwordCreationError(state) {
    state.status = 'password not saved';
  },
  clearToken(state, tokenType) {
    state[tokenType] = {};
    localStorage.removeItem(`PHCP${tokenType}`);
  },
  setRefreshProcessing(state, payload) {
    state.isRefreshProcessing = payload;
  },
  changeRefreshStopover(state, promise) {
    const newStopover = promise || Promise.resolve();
    state.refreshStopover = newStopover;
  },
  setPermissions(state, payload) {
    //modify permission response before use it
    // from ['Join', 'Invite'] -> { Join: true, Invite: true}

    const mappedPermissions = mapValues(payload.permissions, (data) => {
      return Array.isArray(data)
        ? data.reduce((acc, el) => ({ ...acc, [el]: true }), {})
        : data;
    });
    const accountType = payload.context.type;
    let permissions = new PermissionsModel(mappedPermissions);
    //set permissions only once (useful for reload page)
    permissions = {
      ...permissions,
      ...BASE_PERMISSIONS[accountType],
    };
    state.permissions = permissions;
    localStorage.setObjectToItem('permissions', permissions);
  },
  clearPermissions(state) {
    state.permissions = {};
    localStorage.removeItem('permissions');
  },
  clearFlags(state) {
    state.isOrganization = false;
    localStorage.removeItem('isOrganization');
  },
};

const actions = {
  clearAllTokens: ({ commit }) => {
    commit('clearToken', 'tempToken');
    commit('clearToken', 'accessToken');
    commit('clearToken', 'refreshToken');
  },
  authRequest: ({ commit }, user) => {
    return api.auth.signIn(user).then((response) => {
      // commit('authSuccess', {data: response.data, from: 'signin'});
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires * 1000,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires * 1000,
      });
      commit('setStatus', 'authorized');
      commit('clearToken', 'tempToken');
      commit('setPermissions', response.data);
      // return resolve(response.data);
    });
  },
  createUser: ({ getters, commit }, user) => {
    return new Promise((resolve, reject) => {
      commit('userCreateRequest');
      commit('setLogin', user.login);
      api.auth
        .signUp(user)
        .then((response) => commit('codeRequest', response.data))
        .then(() => {
          return getters.tempToken
            ? api.auth.sendCode(getters.tempToken)
            : Promise.reject(new Error('Auth Error: No temporary token!'));
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  verifyUser: (
    { getters: { tempToken }, commit },
    { identity, type, code, setTokens = true }
  ) => {
    commit('codeVerify');
    return api.auth
      .verifyCode(tempToken, { identity, type, code })
      .then(({ data }) => {
        if (setTokens) {
          commit('setToken', {
            type: 'accessToken',
            token: data.access_token,
            expires: data.access_token_expires * 1000,
          });
          commit('setToken', {
            type: 'refreshToken',
            token: data.refresh_token,
            expires: data.refresh_token_expires * 1000,
          });
          commit('setStatus', 'authorized');
          commit('clearToken', 'tempToken');
        }
        return data;
      });
  },
  requestPass: ({ getters, commit }, { identity, type }) => {
    return new Promise((resolve, reject) => {
      commit('requestPass');
      api.auth
        .resetPass({ login: identity })
        .then(
          (response) => {
            commit('codeRequest', response.data);
            return Promise.resolve();
          },
          (error) => Promise.reject(error)
        )
        .then(
          () => {
            return getters.tempToken
              ? api.auth.sendCode(getters.tempToken, { identity, type })
              : Promise.reject(new Error('No temporary token!'));
          },
          (error) => Promise.reject(error)
        )
        .then(
          (response) => resolve(response.data),
          (error) => Promise.reject(error)
        )
        .catch((error) => {
          commit('authError', error);
          reject(error);
        });
    });
  },
  submitPass: ({ getters, commit }, { password, token }) => {
    return new Promise((resolve, reject) => {
      commit('submitPass');
      api.auth
        .submitPass(token || getters.accessToken, { password })
        .then(resolve, reject)
        .catch((error) => {
          commit('authError', error);
        });
    });
  },
  refreshAccessToken: ({ getters, commit, state }) => {
    if (state.isRefreshProcessing) {
      return Promise.resolve();
    }
    commit('setRefreshProcessing', true);
    return api.auth.refreshToken(getters.refreshToken).then((response) => {
      // console.log(response.data);
      commit('setToken', {
        type: 'accessToken',
        token: response.data.access_token,
        expires: response.data.access_token_expires * 1000,
      });
      commit('setToken', {
        type: 'refreshToken',
        token: response.data.refresh_token,
        expires: response.data.refresh_token_expires * 1000,
      });
      commit('setStatus', 'token pare refreshed');
      commit('setRefreshProcessing', false);
      commit('changeRefreshStopover');
    });
  },
  clearExpiredTokens({ getters }) {
    if (!getters.isTokenValid('tempToken')) {
      this.$store.commit('clearToken', 'tempToken');
    }
    if (!getters.isTokenValid('accessToken')) {
      this.$store.commit('clearToken', 'accessToken');
    }
    if (!getters.isTokenValid('refreshToken')) {
      this.$store.commit('clearToken', 'refreshToken');
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
