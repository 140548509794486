import api from '@/api';
export const INITIAL_GLOBAL_SETTINGS_STATE = () => ({
  regions: {},
});

const getters = {
  regionIDs: (state) => Object.keys(state.regions),
};

const mutations = {
  setRegions(state, regions) {
    state.regions = Object.freeze(
      regions.reduce(
        (acc, region) => ({
          ...acc,
          [region.id]: region,
        }),
        {}
      )
    );
  },
};

const actions = {
  getRegions({ commit }) {
    return api.globalSettings
      .getRegions()
      .then((response) => response.data)
      .then((regions) => {
        commit('setRegions', regions);
        return regions;
      });
  },
};

export default {
  state: INITIAL_GLOBAL_SETTINGS_STATE(),
  getters,
  mutations,
  actions,
};
