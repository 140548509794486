import { upperFirst, isUndefined, isObject, camelCase } from 'lodash';

export const generateMutations = (...list) => {
  return list.reduce((acc, key) => {
    acc[`set${upperFirst(key)}`] = (state, payload) => (state[key] = payload);
    return acc;
  }, {});
};

export const generateToggleMutations = (...list) => {
  return list.reduce((acc, key) => {
    acc[`toggle${upperFirst(key)}`] = (state, payload) =>
      (state[key] = isUndefined(payload) ? !state[key] : !!payload);
    return acc;
  }, {});
};

export const generateGetters = (...list) => {
  return list.reduce((acc, key) => {
    acc[key] = (state) => state[key];
    return acc;
  }, {});
};

/**
 * Normalize data from backend.
 * Also can be used instead cloneDeep.
 *
 * Ex:
 *
 * const data = {
 *  display_name: 'SuperAdmin',
 *  avatar: null,
 *  social_metadata: {
 *    is_friend: false
 *  }
 * }
 * normalize(data) // => {
 *  displayName: 'SuperAdmin',
 *  socialMetadata: {
 *    isFriend: false
 *  }
 * }
 *
 * @param {Object} data Source data
 * @return {Object} Normalized data
 */
export const normalize = (data) => {
  const newData = {};
  Object.entries(data).forEach(([key, value]) => {
    if (value === null) {
      return;
    }
    const newKey = camelCase(key);
    newData[newKey] = isObject(value) ? normalize(value) : value;
  });
  return newData;
};
