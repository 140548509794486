import api from '@/api';
export const INITIAL_CALENDAR_STATE = () => ({});

const getters = {};

const mutations = {};

const actions = {
  getTournamentsSchedule({ getters: { accessToken } }, query) {
    return api.reports
      .tournamentsSchedule(accessToken, {
        params: query,
      })
      .then((response) => response.data);
  },
};

export default {
  state: INITIAL_CALENDAR_STATE(),
  getters,
  mutations,
  actions,
};
