import { prop } from 'playhera-utils';
import valueModel from '../mixins/valueModel';

import './classes/inputLabel.scss';
import './classes/inputError.scss';

export default Input => ({
  name: `${Input.name}WithLabelAndError`,

  mixins: [valueModel],

  props: {
    value: prop(String),
    label: prop(String),
    error: prop(String),
    required: prop(Boolean),
    tag: prop(String),
  },

  computed: {
    hasLabel() {
      return !!(
        (this.label && this.label.length) ||
        this.$slots.additionalLabel
      );
    },
    hasError() {
      return !!(this.error && this.error.length);
    },
  },

  render(h) {
    const children = [];

    if (this.hasLabel) {
      children.push(
        h(
          'span',
          {
            class: [
              '_input-label',
              { '_input-label--required': this.required },
            ],
          },
          [this.label, this.$slots.additionalLabel]
        )
      );
    }

    children.push(
      h(Input, {
        domProps: this.$attrs,
        props: { value: this.valueModel },
        class: { '_text-input--is-invalid': this.hasError },
        on: {
          ...this.$listeners,
          input: e => (this.valueModel = e),
        },
      })
    );

    children.push(
      h(
        'span',
        {
          class: ['_input-error', { '_input-error--visible': this.hasError }],
        },
        this.error
      )
    );

    const wrapperEl = this.tag || (this.hasLabel ? 'label' : 'div');
    return h(wrapperEl, children);
  },
});
