<template>
  <div class="d-flex flex-column flex-grow-1" style="min-height: 698px">
    <div id="menu" class="pb-3 flex-shrink-0">
      <div id="menu-nav" class="d-flex align-items-center">
        <TextBlock
          id="renderRange"
          class="render-range"
          variant="primary"
          style="min-width: 105px"
          >{{ currentDate | formatDate(currentDateFormat) }}</TextBlock
        >
        <HeraFormBtn
          label="<"
          class="mx-3 move-today"
          content-color="$scss.textColor"
          @click.native="goPrev"
        />
        <HeraFormBtn
          label="Today"
          class="h-mr-3 move-today"
          content-color="$scss.textColor"
          @click.native="setToday"
        />
        <HeraFormBtn
          label=">"
          class="h-mr-3 move-today"
          content-color="$scss.textColor"
          @click.native="goNext"
        />

        <TextBlock
          v-if="isLoading"
          class="h-mr-3"
          :size="0.875"
          variant="secondary"
          >Loading data...</TextBlock
        >
      </div>
    </div>
    <div class="flex-shrink-0 flex-grow-1 position-relative calendar-wrapper">
      <TuiCalendar
        id="calendar"
        ref="tuiCalendar"
        :calendars="calendars"
        :schedules="schedules"
        :view="view"
        :task-view="taskView"
        :schedule-view="scheduleView"
        :theme="theme"
        :week="week"
        :month="month"
        :disable-dbl-click="disableDblClick"
        :template="template"
        :use-creation-popup="useCreationPopup"
        :use-detail-popup="useDetailPopup"
        :usage-statistics="false"
        is-read-only
        @clickSchedule="onEventClick"
      />
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <template v-for="region in calendarList">
        <HeraFormCheckbox
          :key="region.id"
          v-model="calendarsFilter"
          :val="region.id"
          :label="changeLabelCheckBox(region.name)"
          class="flex-shrink-0 flex-basis-1 h-ml-4 rounded px-2 py-1"
          :style="{ background: $scss.hoverColor }"
        >
          <span
            class="d-inline-block rounded mx-1 p-2 position-relative"
            :style="{ background: region.bgColor, top: '2px' }"
          ></span>
        </HeraFormCheckbox>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { ISOSecondsConvertion } from '@/mixins/dateMethods';
import loadingMixing from '@/mixins/isLoading';
import { Calendar } from '@toast-ui/vue-calendar';
import TextBlock from '@/components/text/TextBlock';
import HeraFormBtn from '@/components/form/HeraFormBtn';
import HeraFormCheckbox from '@/components/form/HeraFormCheckbox';

export default {
  name: 'Calendar',
  components: {
    TuiCalendar: Calendar,
    TextBlock,
    HeraFormBtn,
    HeraFormCheckbox,
  },
  mixins: [ISOSecondsConvertion, loadingMixing],
  data() {
    return {
      calendarsFilter: [],
      calendarList: null,
      scheduleList: null,
      view: 'month',
      taskView: false,
      currentDate: null,
      scheduleView: ['time', 'task', 'allday'],
      theme: {
        'common.border': '1px solid #B4BAC6',
        'common.backgroundColor': this.$scss.card,
        'common.today.color': this.$scss.primaryColor,
        'common.holiday.color': this.$scss.dangerColor,
        'common.saturday.color': this.$scss.dangerColor,
        'common.dayname.color': this.$scss.textColor,
        'month.dayname.height': '36px',
        'month.dayname.borderLeft': '1px solid transparent',
        'month.dayname.fontWeight': 'bold',
        'month.dayname.fontSize': '14px',
        'month.dayname.color': 'white',
        'month.dayname.backgroundColor': 'white',
        'month.dayExceptThisMonth.color': '#44444F',
      },
      week: {
        narrowWeekend: true,
        showTimezoneCollapseButton: true,
        timezonesCollapsed: false,
      },
      month: {
        isAlways6Week: false,
        visibleWeeksCount: 0,
        startDayOfWeek: 1,
        visibleScheduleCount: 99,
      },
      template: {
        // eslint-disable
        monthGridHeaderExceed(hiddenSchedules) {
          return (
            '<span class="weekday-grid-more-schedules">+' +
            hiddenSchedules +
            '</span>'
          );
        },
        // eslint-enable
      },
      disableDblClick: true,
      useCreationPopup: false,
      useDetailPopup: false,
      rangeStart: '',
      rangeEnd: '',
    };
  },
  computed: {
    calendars() {
      return this.calendarList
        ? this.calendarList.filter((el) => this.calendarsFilter.includes(el.id))
        : [];
    },
    schedules() {
      const visibleCalendarsIds = this.calendars.map((calendar) => calendar.id);
      return this.scheduleList
        ? this.scheduleList.filter((schedule) =>
            visibleCalendarsIds.includes(schedule.calendarId)
          )
        : [];
    },
    ...mapState({
      regions: (state) => state.globalSettings.regions,
    }),
    currentDateFormat() {
      return this.view !== 'day' ? 'MMMM, yyyy' : 'dd MMMM, yyyy';
    },
  },
  watch: {
    view: function () {
      this.$nextTick(() => {
        this.updateRange();
      });
    },
    calendars: function (newVal) {
      this.fixCalendarsColors(newVal);
    },
  },
  created() {
    this.$options.dateShortFormat = DateTime.DATE_SHORT;
    this.$options.calendarViews = ['day', 'week', 'month'];
    this.getRegions()
      .then(this.initializeCalendars)
      .then(this.initializeCalendarFilter)
      .catch(this.errorNotify);
  },
  mounted() {
    this.$nextTick(() => {
      this.updateRange();
    });
  },
  methods: {
    ...mapActions(['getTournamentsSchedule', 'errorNotify', 'getRegions']),
    initializeCalendarFilter(regions) {
      this.calendarsFilter = this.$lodash.cloneDeep(regions.map((el) => el.id));
    },
    changeLabelCheckBox(label) {
      let stringArray = label.split(' ');
      return stringArray[0];
    },
    getSchedule() {
      this.setLoading();
      this.getTournamentsSchedule({
        from_date: Math.round(
          DateTime.fromJSDate(this.rangeStart).startOf('day').toSeconds()
        ),
        to_date: Math.round(
          DateTime.fromJSDate(this.rangeEnd).plus({ days: 1 }).toSeconds()
        ),
      })
        .then(this.initializeEvents)
        .catch(this.errorNotify)
        .finally(this.unsetLoading);
    },
    initializeCalendars(regions) {
      this.calendarList = regions.reduce((acc, region, i) => {
        const color = this.$scss[`chartPalette_${i}`];
        return acc.concat([
          {
            id: region.id,
            name: `${region.name}`,
            color: this.$scss.textColor,
            bgColor: color,
            borderColor: color,
          },
        ]);
      }, this.calendarList || []);
      return regions;
    },
    fixCalendarsColors(calendars) {
      for (let i = 0; i < calendars.length; i += 1) {
        this.$refs.tuiCalendar.invoke('setCalendarColor', calendars[i].id, {
          color: calendars[i].color,
          bgColor: calendars[i].bgColor,
          borderColor: calendars[i].borderColor,
        });
      }
    },
    initializeEvents(tournaments) {
      this.scheduleList = tournaments.map((tournament) => ({
        id: tournament.id,
        calendarId: tournament.region,
        title: tournament.title,
        category: 'allday',
        dueDateClass: '',
        start: this.secondsToISO(tournament.dateStart),
        end: this.secondsToISO(tournament.dateEnd),
      }));
      return tournaments;
    },
    setToday() {
      this.$refs.tuiCalendar.invoke('today');
      this.updateRange();
    },
    goNext() {
      this.$refs.tuiCalendar.invoke('next');
      this.updateRange();
    },
    goPrev() {
      this.$refs.tuiCalendar.invoke('prev');
      this.updateRange();
    },
    updateRange() {
      this.rangeStart = this.$refs.tuiCalendar
        .invoke('getDateRangeStart')
        .toDate();
      this.rangeEnd = this.$refs.tuiCalendar.invoke('getDateRangeEnd').toDate();
      this.currentDate = new Date(
        this.rangeStart.valueOf() + (this.rangeEnd - this.rangeStart) / 2
      );
      this.getSchedule();
    },
    onEventClick(calendarEvent) {
      window.open(
        `${
          this.regions[calendarEvent.schedule.calendarId].share_url
        }tournaments/${calendarEvent.schedule.id}`,
        '_blank'
      );
    },
  },
};
</script>

<style lang="scss">
@import '~tui-calendar/dist/tui-calendar.css';
@import '~tui-date-picker/dist/tui-date-picker.css';
@import '~tui-time-picker/dist/tui-time-picker.css';
.tui-full-calendar-layout {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.tui-full-calendar-month-dayname {
  border-top-color: transparent !important;
}
.tui-full-calendar-month-week-item {
  .tui-full-calendar-today {
    .tui-full-calendar-weekday-grid-date-decorator {
      background: $ph-primary-text !important;
      border-radius: 35% !important;
    }
  }
  border-left: 1px solid $secondary-text-color;
  border-right: 1px solid $secondary-text-color;
  &:last-child {
    border-bottom: 1px solid $secondary-text-color;
  }
}

.weekday-grid-more-schedules {
  color: $primary;
  border: 1px solid $primary;
  border-radius: 35%;
  padding: 0.25rem;
  &:hover {
    background: $primary;
    color: $ph-primary-text !important;
  }
}
.calendar-wrapper {
}
#calendar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 16px;
  top: 0;
  .tui-full-calendar-weekday-schedule-title {
    color: white;
  }
}
</style>
