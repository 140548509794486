<template>
  <span
    class="svg-icon-wrapper"
    @mouseenter.self="setHoverIcon"
    @mouseleave.self="setMainIcon"
    v-on="$listeners"
  >
    <svgicon
      :key="displayName"
      :class="{
        [$options.name]: true,
        [`${$options.name}--${size}`]: size,
        [`${$options.name}--rotate`]: rotate,
      }"
      :style="rotate ? { '--rotate': `${rotate}deg` } : null"
      :name="displayName"
      :color="displayFill || ''"
      :width="width"
      height="auto"
      :original="original"
      pointer-events="none"
    >
    </svgicon>
  </span>
</template>

<script>
export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    fill: {
      type: null,
      default: '',
    },
    width: {
      type: String,
      default: '18',
    },
    rotate: {
      type: Number,
      default: 0,
    },
    original: {
      type: Boolean,
    },
    hoverIcon: {
      type: String,
      default: '',
    },
    hoverFill: {
      type: null,
      default: '',
    },
  },
  data() {
    return {
      viewer: '0 0 16 16',
      displayName: this.name,
      displayFill: this.fill,
    };
  },
  watch: {
    fill(value) {
      this.displayFill = value;
    },
    name(value) {
      this.displayName = value;
      import(/* webpackChunkName: "AppIcon-[request]" */ `./icons/${value}.js`);
    },
  },
  created() {
    // The `[request]` placeholder is replaced
    // by the filename of the file which is
    // loaded (e.g. `AppIcon-music.js`).
    import(
      /* webpackChunkName: "AppIcon-[request]" */ `./icons/${this.displayName}.js`
    );
    if (this.hoverIcon) {
      import(
        /* webpackChunkName: "AppIcon-[request]" */ `./icons/${this.hoverIcon}.js`
      );
    }
  },
  methods: {
    setHoverIcon() {
      if (this.hoverIcon) {
        this.displayName = this.hoverIcon;
      }
      if (this.hoverFill) {
        this.displayFill = this.hoverFill;
      }
    },
    setMainIcon() {
      this.displayName = this.name;
      this.displayFill = this.fill;
    },
  },
};
</script>

<style lang="scss">
.svg-icon-wrapper {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  vertical-align: baseline;
}
.AppIcon {
  /*display: inline-block;*/
  /*height: 1em;*/
  color: inherit;
  vertical-align: baseline;
  fill: none;
  stroke: currentColor;

  &--rotate {
    --rotate: 0;
    transform: rotate(var(--rotate));
  }

  &--fill {
    fill: currentColor;
    stroke: none;
  }

  &--s {
    height: 0.5em;
  }

  &--m {
    height: 1em;
  }

  &--l {
    height: 3em;
  }
}
</style>
