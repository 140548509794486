(function (window) {
  function set(key, object) {
    return this.setItem(key, JSON.stringify(object));
  }

  function get(key) {
    return JSON.parse(this.getItem(key));
  }

  if (!window.localStorage.setObjectToItem) {
    window.localStorage.__proto__.setObjectToItem = set;
  }

  if (!window.localStorage.getObjectFromItem) {
    window.localStorage.__proto__.getObjectFromItem = get;
  }

  if (!window.sessionStorage.setObjectToItem) {
    window.sessionStorage.__proto__.setObjectToItem = set;
  }

  if (!window.sessionStorage.getObjectFromItem) {
    window.sessionStorage.__proto__.getObjectFromItem = get;
  }
})(window);
