import { isObject, uniqueId } from 'lodash';
import api from '@/api';
import Vue from 'vue';
import { Subject } from 'rxjs';
import { generateMutations } from '@/utils/store';
import { TEN_SECONDS, FIVE_SECONDS } from '@/constants/time';

const HUB_KEY_PREFIX = 'hub_';

export const NOTIFICATIONS_DEFAULT_STATE = () => ({
  hubs: {},
  notificationsSubject: new Subject(),
  hubKey: uniqueId(HUB_KEY_PREFIX),
  isHubReloadRequired: false,
});

const getters = {
  hubs: (state) => state.hubs,
  notificationsSubject: (state) => state.notificationsSubject,
  hubKey: (state) => state.hubKey,
};

const mutations = {
  notificationsNext(state, data) {
    state.notificationsSubject.next(data);
  },
  addNotificationsSubscriber(state, subscriber) {
    state.notificationsSubject.subscribe(subscriber);
  },
  clearToNotificationsDefaultState(state) {
    const defaultState = NOTIFICATIONS_DEFAULT_STATE();

    Object.keys(defaultState).forEach((key) => {
      Vue.set(state, key, defaultState[key]);
    });
  },
  createHub(state, hub) {
    if (!state.hubs[hub]) {
      Vue.set(state.hubs, hub, {});
    }
  },
  setHubInfo(state, payload) {
    if (state.hubs[payload.hub]) {
      state.hubs[payload.hub] = { ...state.hubs[payload.hub], ...payload.info };
    }
  },
  reconnectHub(state) {
    state.hubKey = uniqueId(HUB_KEY_PREFIX);
  },

  ...generateMutations('isHubReloadRequired'),
};

const actions = {
  getHubConnectionInfo({ commit, getters }, hub) {
    let token = getters.accessToken;
    return !token
      ? Promise.reject('Invalid auth token')
      : api.notifications
          .getHubConnection(hub)(token)
          .then((response) => {
            commit('createHub', hub);
            return response.data;
          });
  },
  initHubConnection({ commit, dispatch }, hub) {
    return dispatch('getHubConnectionInfo', hub).then((hubInfo) => {
      const payload = {
        hub: hub,
        info: hubInfo,
      };
      commit('setHubInfo', payload);
      return payload;
    });
  },
  systemNotify(moduleState, payload) {
    Vue.notify(
      Object.assign(
        {},
        {
          group: 'system',
          duration: FIVE_SECONDS,
        },
        payload
      )
    );
  },
  errorNotify({ dispatch }, error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    const isSilent = isObject(error) && error.silent;
    if (error && !isSilent) {
      const message =
        isObject(error) && error.hasOwnProperty('message')
          ? error.message
          : error;

      dispatch('systemNotify', {
        type: 'error',
        text: message,
        duration: TEN_SECONDS,
      });
    }
  },
  successNotify({ dispatch }, text) {
    dispatch('systemNotify', {
      type: 'success',
      text,
    });
  },
  getNotifications({ getters: { accessToken } }, { pageSize = 3, pageToken }) {
    return api.notifications
      .getNotifications(accessToken, {
        params: { page_size: pageSize, page: pageToken },
      })
      .then((res) => res.data);
  },
  postNotificationAction({ getters: { accessToken } }, { id, action }) {
    return api.notifications
      .postNotificationAction(
        id,
        action
      )(accessToken)
      .then((res) => res.data);
  },

  updateNotificationsStatus({ getters: { accessToken }, dispatch }, IDs) {
    return Promise.all(
      IDs.map((id) =>
        api.notifications.updateNotificationStatus(id)(accessToken, {
          read: true,
        })
      )
    ).then((res) => {
      dispatch('getCounters');
      return res;
    });
  },
  deleteNotificationItem({ getters: { accessToken } }, id) {
    return api.notifications.deleteNotification(id)(accessToken);
  },
};

export default {
  state: NOTIFICATIONS_DEFAULT_STATE(),
  getters,
  mutations,
  actions,
};
