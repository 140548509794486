import { authorizedGetRequest } from '@/api/requests';

// https://stage-global-mena.playhera.com/api/tournamentreports/schedule?from_date=0&to_date=99999999999
const REPORTS = 'tournamentreports';
const STATISTICS = 'corestatistics';

export default {
  tournamentsSchedule: authorizedGetRequest(`${REPORTS}/schedule`),
  getMetrics: authorizedGetRequest(`${STATISTICS}/metrics`),
};
