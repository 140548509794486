import api from '@/api';
import Vue from 'vue';
import { ORGANIZATION_TYPES } from '@/constants/accounts';
import { cloneDeep, isEmpty } from 'lodash';
import { getObjectDifference } from '@/mixins/helpers';

export const INITIAL_PROFILE_STATE = () => {
  return {
    accountType: 'user',
    isLoadedMyDetails: false,
    isProfile: true,
    isOrganization: false,
    isFederation: false,

    localeDirection: '',
    currentProfile: {},
    profileOrganizations: [],
    myProfile: {},
    editProfile: {
      incomplete: false,
      birth_date: 0,
      country: '',
      country_id: '',
      city: '',
      city_id: '',
      description: '',
      gender: '',
      social: {},
    },
    editProfileStart: {},
    isItMyProfile: false,
    isEditProfileMode: false,
    details: {
      login: '',
      incomplete: false,
      birth_date: null,
      country: {},
      city: '',
      description: '',
      gender: null,
      platforms: [],
      games: [],
    },
    avatar: {},
    cover: {},
    posts: [],
    clans: [],
    clansPagination: {
      next: null,
    },
    friends: [],
    organizationOwnerLogin: '',
    // tempClan: {
    //   id: '',
    //   name: '',
    //   logo: '',
    //   cover: '',
    //   country: '',
    //   type: ''
    // }
    // games: {}
  };
};

const getters = {
  accountType: (state) => state.accountType,
  isLoadedMyDetails: (state) => state.isLoadedMyDetails,
  isProfile: (state) => state.isProfile,
  isOrganization: (state) => state.isOrganization,
  isFederation: (state) => state.isFederation,
  login: (state) => state.myProfile.login, // template
  profileId: (state) => state.myProfile[state.isProfile ? 'login' : 'id'],
  isItMyProfile: (state) => state.isItMyProfile,
  isEditProfileMode: (state) => state.isEditProfileMode,
  currentProfile: (state, getters) =>
    getters.isItMyProfile ? state.myProfile : state.currentProfile,
  myAvatar: (state) => state.myProfile.avatar,
  myCover: (state) => state.myProfile.cover,
  myProfile: (state) => state.myProfile,
  myProfileLogin: ({ myProfile, isProfile }) =>
    isProfile ? myProfile.login : myProfile.name,
  myProfileId: ({ myProfile, isProfile }) =>
    isProfile ? myProfile.login : myProfile.id,
  editProfile: (state) => state.editProfile,
  editProfileStart: (state) => state.editProfileStart,

  userCover: (state) => (type) => state.cover[type],
  userAvatar: (state) => (type) => state.avatar[type],
  userDetails: (state) => state.details,
  userPlatforms: (state) => state.details.platforms,
  userGames: (state) => state.details.games || [],
  userClans: (state) => state.clans,
  userFriends: (state) => state.friends,
  userPosts: (state) => state.posts,
  organizationOwnerLogin: (state) => state.organizationOwnerLogin,
  // userLastClan: (state) => state.clans[state.clans.length - 1],
  completeProfile: (state) => !state.details.incomplete,
  clansPaging: (state) => state.clansPagination,
  profileOrganizations: (state) => state.profileOrganizations,
  profileCountry: (state, getters) => () => {
    return {
      id: getters.userDetails.country_id,
      name: getters.userDetails.country,
    };
  },
  hasAccessByPermission:
    (state, { permissions }) =>
    (permission) => {
      return permissions.fullPermissions || permissions[permission] || false;
    },
};

const mutations = {
  setAssociationsList(state, data) {
    if (Array.isArray(data)) {
      //TODO init profile addition fields before save https://isddesign.atlassian.net/browse/TH-7197
      data = data.filter((item) => item);
      data.forEach((item) => {
        if (item) {
          if (item.type === 'profile') {
            item.id = item.login;
            item.name = item.login;
            item.readyForBattle = false;
            item.country = item.country || item.country_id;
          }
        }
      });
      state.profileOrganizations = data.filter((el) => el);
    }
  },
  setAccountType(state, type = 'user') {
    state.isProfile = type === 'user';
    state.isOrganization = ORGANIZATION_TYPES.indexOf(type) !== -1; //organization
    state.accountType = type;
  },
  setLoadedMyDetails(state, payload) {
    state.isLoadedMyDetails = payload;
  },
  //  setUserGames (state, payload) {
  //    state.details.games = payload
  //  },
  setUserPlatforms(state, payload) {
    // console.log(payload)
    let platforms = [];
    if (typeof payload === 'object' && payload !== null) {
      platforms = platforms.concat(Object.keys(payload));
    } else if (typeof payload === 'string' && payload.length) {
      platforms = platforms.concat([payload]);
    }

    if (platforms.length) {
      platforms.forEach((platform) => {
        Vue.set(state.details.platforms, platform, '');
      });
    }
    // state.details.platforms = payload
    // state.games = {}
    // payload.forEach(p => {
    //   if (!state.games[p]) {
    //     console.log('add platform: ', p)
    //     Vue.set(state.games, p, [])
    //   }
    // })
  },
  unsetUserPlatform(state, payload) {
    const oldPlatforms = { ...{}, ...state.details.platforms };
    // console.log("payload -> ", payload.length)
    // console.log("oldPlatforms[payload] -> ", oldPlatforms[payload])
    if (
      payload &&
      payload.length &&
      typeof oldPlatforms[payload] === 'string'
    ) {
      const newPlatforms = { ...{}, ...oldPlatforms };
      // console.log("newPlatforms -> ", newPlatforms)
      delete newPlatforms[payload];
      // console.log("newPlatforms[updated] -> ", newPlatforms)
      state.details.platforms = { ...{}, ...newPlatforms };
    }
    // const index = state.details.platforms.findIndex(el => el.id === payload)
    // if( index > -1 ) {
    //   state.details.platforms.splice(index,1)
    // }
  },
  setLogin(state, payload) {
    state.details.login = payload;
  },
  setUserDetails(state, payload) {
    //payload.platforms = {PSN: null, XBOX: 'chaz', 'BNET.EU': 'zack'};
    state.details = { ...state.details, ...payload };
  },
  updateMyProfile(state, payload) {
    state.myProfile = { ...cloneDeep(state.myProfile), ...payload };
  },
  setMyProfile(state, payload) {
    state.myProfile = { ...payload };
  },
  setMyProfileAvatar(state, payload) {
    state.myProfile.avatar = payload;
    const myProfileInOrgList = state.profileOrganizations.find(
      (el) => el.login === state.myProfile.login
    );
    if (myProfileInOrgList) {
      myProfileInOrgList.avatar = payload;
    }
  },
  setMyProfileCover(state, payload) {
    state.myProfile.cover = payload;
  },
  updateEditProfile(state, payload) {
    state.editProfile = { ...state.editProfile, ...payload };
  },
  setCurrentProfile(state, payload) {
    state.currentProfile = { ...payload };
  },
  setCurrentProfileAvatar(state, payload) {
    state.currentProfile.avatar = payload;
  },
  setEditProfile(state) {
    const tmpProfile = cloneDeep(state.myProfile);
    Object.assign(state.editProfile, tmpProfile);
    state.editProfileStart = cloneDeep(state.editProfile);
  },
  updateIsItMyProfile(state, payload) {
    state.isItMyProfile = payload;
  },
  setIsEditProfileMode(state, payload) {
    state.isEditProfileMode = payload;
  },
  setAvatar(state, payload) {
    state.avatar = { ...state.avatar, ...{ original: payload } };
  },
  clearAvatar(state) {
    state.avatar = {};
  },
  setCover(state, payload) {
    state.cover = { ...state.cover, ...{ original: payload } };
  },
  clearCover(state) {
    state.cover = {};
  },
  setClans(state, payload) {
    // TODO: switch later condition from clan.nem to clan.id https://isddesign.atlassian.net/browse/TH-7196
    if (Array.isArray(payload)) {
      payload.forEach((clan) => {
        if (state.clans.findIndex((el) => el.name === clan.name) < 0) {
          state.clans.push(clan);
        }
      });
    } else {
      if (payload) {
        state.clans.push(payload);
      }
    }
  },
  setFriends(state, payload) {
    if (Array.isArray(payload)) {
      payload.forEach((friend) => {
        if (state.friends.findIndex((el) => el.login === friend.login) < 0) {
          state.friends.push(friend);
        }
      });
    } else {
      if (payload) {
        state.friends.push(payload);
      }
    }
  },
  addClan(state, payload) {
    // TODO: switch later condition from clan.name to clan.id https://isddesign.atlassian.net/browse/TH-7196
    if (state.clans.findIndex((el) => el.name === payload.name) < 0) {
      let newClan = { ...{}, ...payload };
      newClan.logo = newClan.logoUri;
      newClan.cover = newClan.coverUri;
      delete newClan.coverUri;
      delete newClan.logoUri;
      state.clans.unshift(newClan);
    }
  },

  addPost(state, data) {
    state.posts = [data, ...state.posts];
  },

  deletePost(state, id) {
    state.posts = state.posts.filter((item) => item.id !== id);
  },

  // updatePostLikes(state, data) {

  // },

  setNextClansPageToken(state, payload) {
    state.clansPagination.next = payload;
  },
  setPosts(state, data) {
    if (data.items) {
      state.posts = [...state.posts, ...data.items];
    }
  },

  followCurrentProfile(state, status) {
    const metaData = state.currentProfile.social_metadata;
    metaData.is_follow = status;
    if (status) {
      metaData.followed_by += 1;
    } else {
      metaData.followed_by -= 1;
    }
  },

  friendRequestCurrentProfile(state, status) {
    const metaData = state.currentProfile.social_metadata;
    metaData.is_friend_requested = status;
    if (status && !metaData.is_follow) {
      metaData.is_follow = true;
      metaData.followed_by += 1;
    }
  },

  removeFromFriends(state) {
    const metaData = state.currentProfile.social_metadata;
    if (metaData.is_friend) {
      metaData.friends -= 1;
    }
    metaData.is_friend_requested = false;
    metaData.is_friend = false;
  },

  clearToUserDefaultState(state) {
    const defaultState = INITIAL_PROFILE_STATE();

    Object.keys(defaultState).forEach((key) => {
      Vue.set(state, key, defaultState[key]);
    });
  },

  setOrganizationOwnerLogin(state, login) {
    state.organizationOwnerLogin = login;
  },
};

const actions = {
  getMy: ({ dispatch }) => {
    // console.log('!')
    // debugger;
    return Promise.all([
      dispatch('getMyDetails'),
      // dispatch('getFriends'),
      //dispatch('fetchUserClans')
    ]);
  },
  getMyDetails: ({ getters: { accessToken }, commit }) => {
    // debugger;
    return api.profiles.getMyProfile(accessToken).then((response) => {
      const data = cloneDeep(response.data);
      data.id = data.login;

      if (data.type === 'organization') {
        data.login = data.name;
        commit('setAccountType', data.organizationType);
      } else {
        commit('setAccountType', 'user');
      }
      commit('setMyProfile', data);
      commit('setUserDetails', data);
      commit('setCover', data.cover);
      commit('setAvatar', data.avatar);
      commit('setLoadedMyDetails', true);
    });
  },
  getProfileOrganizationsList({ getters, commit }) {
    return api.profiles
      .getMyProfileAssociations(getters.accessToken)
      .then((response) => {
        commit('setAssociationsList', response.data);

        const userProfile = response.data.find((el) => el.type === 'profile');
        if (userProfile instanceof Object) {
          commit('setOrganizationOwnerLogin', userProfile.login);
        }
      });
  },
  switchAccount({ getters, commit }, { accountId, accountType }) {
    return api.auth
      .switchAccount(getters.accessToken, { id: accountId, type: accountType })
      .then((response) => {
        commit('authSuccess', response);
        commit('setPermissions', response.data);
      });
  },
  fetchUserDetails: ({ getters, commit }, username) => {
    return new Promise((resolve, reject) => {
      api.profiles
        .getProfile(username)(getters.accessToken)
        .then(({ data }) => {
          data.id = data.login;
          commit('setCurrentProfile', data);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  fetchOrganizationDetails: ({ getters, commit }, id) => {
    return api.organizations
      .getOrganizationProfile(id)(getters.accessToken)
      .then(({ data }) => {
        data.login = data.name;
        data.id = id;
        commit('setAccountType', data.organizationType);
        commit('setCurrentProfile', data);
      });
  },
  getFriends: ({ getters, commit }) => {
    return new Promise((resolve, reject) => {
      api.profiles
        .getMyFriends(getters.accessToken)
        .then((response) => {
          commit('setFriends', response.data.friends);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  setAvatar: ({ getters, commit }, fileData) => {
    let fileId = '';
    let displayUri = '';

    return !fileData
      ? api.profiles.putMyAvatar(getters.accessToken)
      : new Promise((resolve, reject) => {
          api.files
            .filePreupload(getters.accessToken, {
              file_name: fileData.name,
              file_size: fileData.size,
            })
            .then((response) => {
              displayUri = response.data.display_uri;
              fileId = response.data.file_id;
              // {
              //   "upload_uri" : "http://someuri.azure.com/cv=adfhjdsdfkjsdfs",
              //   "display_uri" : "http://someuri.azure.com/",
              //   "uri_expire_date" : 1234545667,
              //   "file_id" : "abcdefgh"
              // }
              return response.data.upload_uri;
            })
            .then((url) =>
              api.files.fileUpload(url)({
                blob: fileData.blob,
                size: fileData.size,
              })
            )
            .then(() => {
              commit('setMyProfileAvatar', displayUri);
              commit('setCurrentProfileAvatar', displayUri);
              return api.profiles.putMyAvatar(getters.accessToken, fileId);
            })
            .then(resolve)
            .catch((error) => reject(error));
        });
  },
  setCover: ({ getters, commit }, fileData) => {
    let fileId = '';
    let displayUri = '';
    return !fileData
      ? api.profiles.putMyCover(getters.accessToken)
      : new Promise((resolve, reject) => {
          api.files
            .filePreupload(getters.accessToken, {
              file_name: fileData.name,
              file_size: fileData.size,
            })
            .then((response) => {
              displayUri = response.data.display_uri;
              fileId = response.data.file_id;
              // {
              //   "upload_uri" : "http://someuri.azure.com/cv=adfhjdsdfkjsdfs",
              //   "display_uri" : "http://someuri.azure.com/",
              //   "uri_expire_date" : 1234545667,
              //   "file_id" : "abcdefgh"
              // }
              return response.data.upload_uri;
            })
            .then((url) =>
              api.files.fileUpload(url)({
                blob: fileData.blob,
                size: fileData.size,
              })
            )
            .then(() => {
              commit('setMyProfileCover', displayUri);
              return api.profiles.putMyCover(getters.accessToken, fileId);
            })
            .then(resolve)
            .catch((error) => reject(error));
        });
  },
  setDetails: ({ commit, getters }, details) => {
    // {
    //   incomplete: true,
    //   birth_date: this.birthDateInSeconds,
    //   country: this.userCountry,
    //   city: this.userCity,
    //   description: this.userDescription,
    //   gender: this.userGender
    // }
    commit('setUserDetails', details);
    commit('updateMyProfile', details);

    if (getters.isOrganization) {
      return api.organizations.putOrganizationDetails(getters.profileId)(
        getters.accessToken,
        details
      );
    }
    return api.profiles.putMyDetails(getters.accessToken, details);
  },
  setUserPlatforms: ({ commit, getters }, platforms) => {
    if (!getters.accessToken) return;
    return new Promise((resolve, reject) => {
      commit('setUserPlatforms', platforms);
      api.profiles
        .putMyPlatforms(getters.accessToken, platforms)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  addPlatformToProfile: ({ commit, getters }, platformId) => {
    if (!getters.accessToken) return;
    return new Promise((resolve, reject) => {
      commit('setUserPlatforms', platformId);
      api.profiles
        .putMyPlatform(platformId)(getters.accessToken)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  removePlatformFromProfile: ({ commit, getters }, platformId) => {
    if (!getters.accessToken) return;
    return new Promise((resolve, reject) => {
      commit('unsetUserPlatform', platformId);
      api.profiles
        .deleteMyPlatform(platformId)(getters.accessToken)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  setUserGames: ({ commit, getters }, games) => {
    //console.log(games)
    // let userGames = {}
    // Object.keys(state.games).forEach(p => {
    //   let allGames = getters.gamesByPlatform(p)
    //   userGames[p] = games.filter(g => allGames.map(el => el.id).includes(g))
    // })
    if (!getters.accessToken) return;
    return new Promise((resolve, reject) => {
      commit('setUserGames', games);
      api.profiles
        .putMyGames(getters.accessToken, games)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  setClanAvatar: ({ getters }, fileData) => {
    let fileId = '';
    let displayUri = '';
    return new Promise((resolve, reject) => {
      api.files
        .filePreupload(getters.accessToken, {
          file_name: fileData.name,
          file_size: fileData.size,
        })
        .then((response) => {
          displayUri = response.data.display_uri;
          fileId = response.data.file_id;
          // {
          //   "upload_uri" : "http://someuri.azure.com/cv=adfhjdsdfkjsdfs",
          //   "display_uri" : "http://someuri.azure.com/",
          //   "uri_expire_date" : 1234545667,
          //   "file_id" : "abcdefgh"
          // }
          return response.data.upload_uri;
        })
        .then((url) =>
          api.files.fileUpload(url)({
            blob: fileData.blob,
            size: fileData.size,
          })
        )
        .then(() => {
          return {
            id: fileId,
            url: displayUri,
          };
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  setClanCover: ({ getters }, fileData) => {
    let fileId = '';
    let displayUri = '';
    return new Promise((resolve, reject) => {
      api.files
        .filePreupload(getters.accessToken, {
          file_name: fileData.name,
          file_size: fileData.size,
        })
        .then((response) => {
          displayUri = response.data.display_uri;
          fileId = response.data.file_id;
          // {
          //   "upload_uri" : "http://someuri.azure.com/cv=adfhjdsdfkjsdfs",
          //   "display_uri" : "http://someuri.azure.com/",
          //   "uri_expire_date" : 1234545667,
          //   "file_id" : "abcdefgh"
          // }
          return response.data.upload_uri;
        })
        .then((url) =>
          api.files.fileUpload(url)({
            blob: fileData.blob,
            size: fileData.size,
          })
        )
        .then(() => {
          return {
            id: fileId,
            url: displayUri,
          };
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
  createClan: ({ commit, getters }, details) => {
    // {
    // "Name": "Unique name of the clan",
    // "type": "public",
    // "country": "Ukraine",
    // "city": "Dnipro",
    // "cover": "dGVzdF82O2ltYWdlczthdHRhY2htZW50MTtqcGVn",
    // "logo": "wGVzdF82O2ltYWdlczthdHRhY2htZW50MTtqcGVg"
    // "logoUri": "http://someuri.azure.com/",
    // "coverUri":  "http://someuri.azure.com/"
    // }
    let clan = { ...{}, ...details };
    delete clan.logoUri;
    delete clan.coverUri;
    if (!getters.accessToken) return;
    return new Promise((resolve, reject) => {
      api.clans
        .postClan(getters.accessToken, details)
        .then((response) => {
          commit('addClan', details);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  uploadPostImages({ getters }, fileData) {
    return new Promise((resolve, reject) => {
      api.files
        .filePreupload(getters.accessToken, {
          file_name: fileData.name,
          file_size: fileData.size,
        })
        .then((response) => {
          // {
          //   "upload_uri" : "http://someuri.azure.com/cv=adfhjdsdfkjsdfs",
          //   "display_uri" : "http://someuri.azure.com/",
          //   "uri_expire_date" : 1234545667,
          //   "file_id" : "abcdefgh"
          // }
          return response.data;
        })
        .then((data) => {
          return new Promise((resolve) => {
            api.files
              .fileUpload(data.upload_uri)({
                blob: fileData.blob,
                size: fileData.size,
              })
              .then(() => resolve(data));
          });
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  createPost({ commit, getters }, post) {
    return new Promise((resolve, reject) => {
      api.posts
        .createPost(getters.accessToken, post.send)
        .then((response) => {
          let newPost = { ...post.display, id: response.data.post_Id };

          commit('addPost', newPost);
          resolve(newPost);
        })
        .catch((error) => reject(error));
    });
  },

  createPollPost({ getters: { accessToken } }, payload) {
    return api.posts
      .createPollPost(accessToken, payload)
      .then(({ data: { post_Id } }) => api.posts.getPost(accessToken, post_Id))
      .then(({ data: { post } }) => post);
  },

  votePollPost({ getters: { accessToken } }, payload) {
    return api.posts.votePollPost(payload)(accessToken);
  },

  deletePost({ commit, getters }, id) {
    return api.posts
      .deletePost(id)(getters.accessToken)
      .then((response) => {
        commit('deletePost', id);
        return response;
      })
      .catch((error) => error);
  },

  likePost({ getters }, post) {
    let params = post.reacted
      ? [getters.accessToken, { type: 'none' }]
      : [getters.accessToken, { type: post.type }];

    return new Promise((resolve, reject) => {
      api.posts
        .likePost(post.id)(...params, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  sendInviteToClan: ({ getters }, payload) => {
    return new Promise((resolve, reject) => {
      let invites = [];
      if (typeof payload.invites === 'string') {
        invites.push(payload.invites);
      } else {
        invites = payload.invites;
      }
      if (invites.length) {
        // console.log(invites)
        api.clans
          .inviteClanMember(payload.clan)(getters.accessToken, invites)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      } else {
        throw new Error('No invites to send');
      }
    });
  },

  fetchUserClans: ({ commit, getters }, pageToken) => {
    return new Promise((resolve, reject) => {
      api.profiles
        .getMyClans(pageToken)(getters.accessToken)()
        .then((response) => {
          const clans = response.data.clans;
          commit('setClans', clans);
          commit('setNextClansPageToken', response.data.next_page_token);
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  /*
  payload {
    pageToken,
    userName
  }
  */
  fetchUserWall({ getters }, payload) {
    // commit('lockScreen');
    let userName = payload.userName;
    return new Promise((resolve, reject) => {
      api.posts
        .getWall(
          userName,
          payload.pageToken
        )(getters.accessToken)
        .then((response) => {
          // commit('setPosts', data);
          // commit('unlockScreen');
          resolve(response.data);
        })
        .catch((error) => {
          // commit('unlockScreen');
          reject(error);
        });
    });
  },
  setSocialNetworks({ getters }, socials) {
    /*
    let requests = [];

    for (let name in socials) {
      requests.push(api.putSocialNetwork(name)(getters.accessToken, {'account_id': socials[name]}));
    }
    requests.push(api.putSocialNetwork('asd')(getters.accessToken, {'account_id': 'ads'}));

    console.log(requests);

    Promise.all(requests).then(results => {
      console.log(results)

      results.forEach((item, index) => {
        console.log(item.config.data);
      })
    })
    */

    return new Promise((resolve) => {
      let chain = Promise.resolve();

      for (let name in socials) {
        chain = chain.then(() => {
          return new Promise((resolve, reject) => {
            api[socials[name] ? 'putSocialNetwork' : 'deleteSocialNetwork'](
              name
            )(getters.accessToken, { account_id: socials[name] })
              .then(resolve)
              .catch(reject);
          });
        });
      }

      chain.then(() => resolve());
    });
  },

  followUser({ commit, getters }, payload) {
    if (!isEmpty(getters.currentProfile)) {
      commit('followCurrentProfile', payload.follow);
    }
    return api.requests.followUser(getters.accessToken, {
      login: payload.login,
      follow: payload.follow,
    });
  },

  friendRequestUser({ commit, getters }, username) {
    commit('friendRequestCurrentProfile', true);
    return api.requests.friendRequest(username)(getters.accessToken);
  },

  removeFromFriends({ commit, getters }, username) {
    commit('friendRequestCurrentProfile', false);
    commit('removeFromFriends');
    return api.requests.removeFromFriends(username)(getters.accessToken);
  },

  getPostDetails({ getters: { accessToken } }, postId) {
    return new Promise((resolve, reject) => {
      api.posts.getPost(accessToken, postId).then(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  },
  /**
   *
   */
  saveProfileFromEdit({ getters, dispatch }) {
    return Promise.all([
      ...(getters.isOrganization
        ? []
        : [dispatch('saveNewAvatarImage'), dispatch('saveNewCoverImage')]),
      dispatch('saveEditProfileAbout'),
    ]).then(() => dispatch('getMyDetails'));
  },

  saveEditProfileAbout({
    getters: {
      editProfile,
      editProfileStart,
      editCoverImageTempt,
      editAvatarImageTemp,
      coverIsDeleted,
      avatarIsDeleted,
      isOrganization,
    },
    dispatch,
  }) {
    let diff = getObjectDifference(editProfile, editProfileStart);

    const newCover = !!editCoverImageTempt && !coverIsDeleted;
    const newAvatar = !!editAvatarImageTemp && !avatarIsDeleted;

    return Promise.resolve()
      .then(() => {
        if (isOrganization) {
          return Promise.all([
            newCover
              ? dispatch('sendFile', editCoverImageTempt)
              : Promise.resolve(),
            newAvatar
              ? dispatch('sendFile', editAvatarImageTemp)
              : Promise.resolve(),
          ]);
        }
      })
      .then(([coverRes, avatarRes] = []) => {
        if (coverIsDeleted) {
          diff.cover = '';
        } else if (coverRes) {
          diff.cover = coverRes.file_id;
        }

        if (avatarIsDeleted) {
          diff.avatar = '';
        } else if (avatarRes) {
          diff.avatar = avatarRes.file_id;
        }
      })
      .then(() => {
        if (!isEmpty(diff)) {
          return dispatch('setDetails', diff);
        } else {
          return Promise.resolve();
        }
      });
  },

  async repost({ getters: { accessToken } }, params) {
    let res = api.posts.postRepost(accessToken, params);
    return res;
  },
};

export default {
  state: INITIAL_PROFILE_STATE(),
  getters,
  mutations,
  actions,
};
