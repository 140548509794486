<template>
  <div
    class="card hera-card-panel"
    :class="[
      `hera-card-panel--${variant}`,
      {
        'load-data': isLoadData,
        'is-loading': isLoading,
        'is-loading-error': isLoadingError,
      },
    ]"
  >
    <div class="card-body m-0 p-0">
      <slot></slot>
    </div>
    <Loader v-if="isLoading && isLoading" />
  </div>
</template>

<script>
import Loader from '@/components/common/Loader';

export default {
  name: 'CardPanel',
  components: {
    Loader,
  },
  props: {
    variant: {
      type: String,
      default: 'transparent',
      validator(val) {
        return [
          'transparent',
          'hover',
          'primary-card',
          'secondary-card',
          'danger-card',
          'addon-card',
          'active-card',
        ].includes(val);
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingError: {
      type: Boolean,
      default: false,
    },
    isLoadData: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.hera-card-panel {
  border-radius: 0.25rem;
  padding: 20px;

  &--transparent {
    background-color: transparent !important;
  }

  &--hover {
    background-color: $hera-hover !important;
  }

  &--primary-card {
    background-color: $hera-primary-card-bg !important;
  }

  &--secondary-card {
    background-color: $hera-secondary-card-bg !important;
  }

  &--danger-card {
    //background: $danger !important;
    background-color: $panel-danger-bg-color;
    border: 1px solid $danger;
  }

  &--addon-card {
    background-color: $hera-card-addon-bg !important;
  }

  &--active-card {
    background-color: $primary-color;
  }
}

.load-data {
  min-height: 100px;
  transition: box-shadow 0.25s linear;
  &.is-loading {
    //animation: loader 1.5s infinite linear;
    position: relative;
    z-index: 1;
    overflow: hidden;
    > .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      z-index: 5;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: 5rem;
      background: $dark-bg-color;
      opacity: 0.9;
    }
  }
  &.is-loading-error {
    box-shadow: inset 0 0 30px rgba(182, 93, 252, 0.5);
  }
}
</style>
